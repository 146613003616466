.messages {
    padding:em(0);
	.message{
		padding: em(10);
		margin-bottom: em(10);
		background-color: #f2f2f2;
		border: solid 1px #e7e7e7;
		
		&.error{
			color: #450303;
			background-color: #fcc2c2;
			border-color: #e08585;
			a{
				color: #280202;
			}
		}
		&.warning{
			background-color: #fbf0a8;
			border-color: #c5ac08;
			color:#4e4403;
			a{
				color: #252001;
			}
		}
		&.notice{
			background-color: #bacee4;
			border-color: #628fc2;
			color:#1e334c;
			a{
				color: #0d1520;
			}
		}
		&.success{
			background-color: #def8e4;
			border-color: #5ea970;
			color:#27482e;
			a{
				color: #1c3421;
			}
		}
		
	}
}

div.mage-error[generated] {
    margin-top: 7px;
    margin-bottom: 7px;
}

div.mage-error[generated] {
    color: #e02b27;
    font-size: 0.8rem;
}