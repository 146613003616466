.radio__container {
    position:relative;
    line-height:em($body-fontsize);
}
.radio {
    position:absolute;
    left:em(-9999);
    &+ .label {
        position:relative;
        display:inline-block;
        padding-left:em(30);
        &:before {
            content:'';
            display:block;
            width:em(18);
            height:em(18);
            background:$color-white-smoke;
            border:solid 3px $color-white-smoke;
            @include roundedCorners(em(20));
            box-shadow: 0px 0px 0px 1px $color-silver,
                inset 0 8px 10px -10px $color-silver;
            position:absolute;
            top:50%;
            margin-top:em(-9);
            left:em(0);
        }
        &:hover {
            cursor:pointer;
        }
    }
    &:checked+.label:before {
        @include linearGradient1();
    }
}
