.cms-home {
	
	.promo-tile-banner {
		margin-bottom: em(14);
	}
	
	.twitter-latest {
		margin-top: em(25);
	}
	
	.brands-ranges {
		@include clearfix;
		
		.content-columns__col {
			
			@media screen and (min-width: 800px){
				width: 50%;
				float: left;
				padding-right: em(5);
			}
			
			@media screen and (min-width: 1000px){
				width: 66.66666666%;
			}
		}
		
		.content-columns__col+.content-columns__col {
			margin-top: em(10);
			
			@media screen and (min-width: 800px){
				margin-top: 0;
				width: 50%;
				float: right;
				
			}
			
			@media screen and (min-width: 1000px){
				width: 33.33333333%;
			}
		}
	}
	
	.showcase-note {
		margin-top:em(25);
		h4 + p {
			margin-top:0;
		}
	}
	
}