html {
    min-height: 100%;

    box-sizing: border-box;
    -webkit-backface-visibility: hidden;
}

*,
*:before,
*:after {
    font-family: inherit;

    background-repeat: no-repeat;
    background-position: 50% 50%;

    box-sizing: inherit;
}

*:hover {
    -webkit-tap-highlight-color: rgba(0,0,0,0);
}

body {
    min-height: 100%;

    font-size: #{$body-fontsize}px;
    font-family: $font-stack-default;
    line-height: $body-lineheight;
    color: $body-color;

    background-color: $body-background;
}

.clearfix {
    @include clearfix();
}

.video__container {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    padding-top: 25px;
    height: 0;
    iframe,
    object,
    embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.loading-mask {
    bottom: 0;
    left: 0;
    margin: auto;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 100;
    background: rgba(255, 255, 255, 0.5);
	.loader > img {
		bottom: 0;
		left: 0;
		margin: auto;
		position: fixed;
		right: 0;
		top: 0;
		z-index: 100;
	}
}