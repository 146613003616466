.toolbar__sorter {
    float:right;
}
    .sorter__label {
        display:inline-block;
        font-family:$font-stack-default;
        font-size:em(13);
    }
    .sorter__control {
        display:inline-block;
    }
    
     
@media screen and (max-width: 830px) {
    .toolbar__sorter {
        display:none;
    }
    
    .toolbar__mobile--active {
        .toolbar__sorter {
            float:none;
            display:block;
        }
        .sorter__label {
            display:block;
            font-size:em($body-fontsize);
            margin-bottom:em(5);
            font-family:$font-stack-default--bold;
        }
        .sorter__control {
            display:block;
        }
    }
}
