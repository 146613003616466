.price-wrapper {
    display:block;
    font-family:$font-stack-special;
    &+ .price-wrapper {
        margin-top:em(2);
    }
    &:after {
        content:'('attr(data-label)')';
    }
}
.price-including-tax {
    color:$color-gondola;
    &:after {
    }
    .price {
        font-size: em($body-fontsize + 2);
    }
}
.price-excluding-tax {
    color:$color-scarlet;
    &:after {
        font-size: em(14);
    }
    .price {
        font-size: em($body-fontsize + 10);
		margin-right: 4px;
    }
}

.special-price{
	.price-label{
		display:none;
	}
}

.old-price{
	font-size: 0.725rem;
	.price-including-tax {
		display: inline;
		color: inherit;
		font-family:$font-stack-default;
		&:after {
		}
		.price {
			font-size: 1em;
		}
	}
}

.prices-tier {
	padding-left: 0;
	margin-bottom: 15px;
	
	.price-wrapper{
		display:inline;
	}
	
	.price-including-tax
	{
		&.price-wrapper:after{
			content:'(inc VAT)';
		}
		.price{
			font-size: 1em;
		}
	}
	.price-excluding-tax .price{
		font-size: 1em;
	}
}