.discount__heading {
    display:block;
    color:$typography-heading-color;
    font-family:$font-stack-default--bold;
    margin-bottom:em(5);
}
.discount__label {
    display:block;
    font-family:$font-stack-default;
    margin-bottom:em(10);
}