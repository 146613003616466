.grid--crosssell {
    margin-left:em(-24);
    width:100%;
    width:calc(100% + #{em(49)});
    .grid__items {
        margin-left:-10px;
        width:calc(100% + #{em(20)});
    }
    .grid__item {
        width:16.66%;
        padding-left:5px;
        padding-right:5px;
        &:nth-child(n+7) {
            display:none;
        }
    }
}
.crosssell {
}
.crosssell__title {
    text-align:center;
    margin-bottom:em(20);
    overflow:hidden;
    strong {
        display:inline-block;
        position:relative;
        &:before,
        &:after {
            content:'';
            display:block;
            height:2px;
            width:1000px;
            background-color:$color-scarlet;
            position:absolute;
            top:50%;
            margin-top:-1px;
        }
        &:before {
            left:100%;
            margin-left:10px;
        }
        &:after {
            right:100%;
            margin-right:10px;
        }
    }
}
.crosssell__controls {
    text-align:center;
    margin-top:em(20);
}


@media screen and (max-width: 1150px) {
    .grid--crosssell .grid__item {
        width:20%;
        display:inline-block;
        &:nth-child(n+6) {
            display:none;
        }
    }
}
@media screen and (max-width: 1000px) {
    .grid--crosssell .grid__item {
        width:25%;
        display:inline-block;
        &:nth-child(n+5) {
            display:none;
        }
    }
}
@media screen and (max-width: 850px) {
    .grid--crosssell .grid__item {
        width:33.33%;
        &:nth-child(n+4) {
            display:none;
        }
    }
}
@media screen and (max-width: 700px) {
    .grid--crosssell {
        margin-left:em(-15);
        width:100%;
        width:calc(100% + #{em(30)});
        .grid__items {
            margin:0;
            width:100%;
        }
        .grid__item {
            width:100%;
            padding-left:0;
            padding-right:0;
        }
    }
}