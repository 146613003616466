.cart-totals {
    margin-top:em(20);
    padding-top:em(20);
    border-top:solid 1px $border-grey;
    table {
        width:100%;
        tr {
            th, td {
                border-top:solid 1px $border-grey;
                vertical-align:middle;
            }
            &:first-child {
                th, td {
                    border-top:none;
                    padding-top:0;
                }
            }
            &.totals {
                &.shipping {
                    .value {
                        display:block;
                        color:$color-storm-grey;
                    }
                }
                &.grand {
                    th, td {
                        border:solid 1px $color-scarlet;
                        font-family:$font-stack-special;
                        padding:em(5) em(10);
                        background-color:$color-snow;
                    }
                    th {
                        text-transform:uppercase;
                        border-radius:em(5) 0 0 em(5);
                        border-right:none;
                        font-size:em($body-fontsize + 4);
                        strong {
                            display:inline-block;
                            vertical-align:middle;
                            margin-right:em(2.5);
                        }
                        span {
                            display:inline-block;
                            text-transform:none;
                            font-size:em($body-fontsize - 4);
                            line-height:em($body-fontsize - 4);
                            vertical-align:middle;
                        }
                    }
                    td {
                        border-radius:0 em(5) em(5) 0;
                        border-left:none;
                        color:$color-scarlet;
                        font-size:em($body-fontsize + 10);
                    }
                }
            }
        }
        th, td {
            padding:em(10);
        }
        th {
            text-align:left;
            font-family:$font-stack-default--bold;
            color:$typography-heading-color;
        }
        td {
            text-align:right;
        }
    }
}