.category-description + .toolbar {
    border-top:none;
}

.toolbar {
    border:solid 1px $border-grey;
    padding:em(10) em(30);
    @include linearGradient3();
    color:$color-gondola;
}
.toolbar--top {
    .pages {
        display:none;
    }
}
.toolbar--bottom {
    .amount,
    .limiter,
    .sorter {
        display:none;
    }
}
    .toolbar__number {
        color:$color-scarlet;
    }
    .toolbar__mobile {
        float:right;
    }

    
@media screen and (max-width: 830px) {
    .toolbar__mobile {
        float:none;
        padding:em(20);
        border:solid 1px $border-grey;
        border-top:none;
        margin-bottom:em(20);
    }
}

    
@media screen and (max-width: 700px) {
    .toolbar {
        padding-left:em(15);
        padding-right:em(15);
    }
}
