.label {
    font-family:$font-stack-default--bold;
    line-height:em($body-fontsize) + 0.4;
    color:$typography-heading-color;
}


.fieldset {
    &> .field.required > .label:after, 
    &> .fields > .field.required > .label:after, 
    &> .field._required > .label:after, 
    &> .fields > .field._required > .label:after {
        content: '*';
        color: $color-scarlet;
        margin: 0 0 0 em(2.5);
    }
}