.header-basket__container {
    display:inline-block;
    vertical-align:middle;
    @include roundedCorners(em(5));
    overflow:hidden;
    border:solid 1px $color-silver;
}
.header-basket {
    display:block;
    text-decoration:none;
    position:relative;
    padding-left:em(34);
    .button {
        z-index:5;
    }
}
.header-basket__button {
    display:block;
    position:absolute;
    top:em(-1);
    left:em(-1);
}
.header-basket__qty {
    display:none;
}
.header-basket__content {
    display:block;
    @include clearfix();
    @include linearGradient3();
    @include roundedCorners(em(0) em(5) em(5) em(0));
    padding:em(4) em(4) em(4) em(12);
}
.header-basket__price {
    padding:em(6) em(20) em(6) em(0);
    display:inline-block;
    color:$color-mortar;
}
.header-basket__text {
    float:right;
    display:inline-block;
    @include roundedCorners(em(5));
    border:solid 1px $color-silver;
    background-color:$color-white;
    padding:em(6);
    color:$color-scarlet;
    font-size:em(13);
    font-family:$font-stack-default--bold;
}

@media screen and (max-width: 760px) {
    .header-basket {
        padding:em(0);
    }
    .header-basket__container {
        border:none;
		vertical-align: top;
    }
    .header-basket__content {
        display:none;
    }
    .header-basket__button {
        position:relative;
        top:auto;
        left:auto;
        @include roundedCorners(em(5));
    }
}