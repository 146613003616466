/*

Sorry for the mess.... It's copy paste from chrome from Luma... 
Runing out of time.

*/

.modals-overlay {
    background-color: rgba(51, 51, 51, 0.55);
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
}

.modals-overlay {
    z-index: 8990;
}

.nav-sections-items, .modals-wrapper {
    display: block;
}

.modal-popup._inner-scroll {
    overflow-y: visible;
}

.modal-popup {
    z-index: 9000;
    left: 0;
    overflow-y: auto;
	position:absolute;
}

.modal-slide {
    left: 44px;
    z-index: 9000;
}

.modal-slide, .modal-popup {
    bottom: 0;
    left: 0;
    min-width: 0;
    position: fixed;
    right: 0;
    top: 0;
    visibility: hidden;
}

.modal-popup._inner-scroll {
    overflow-y: visible;
}

.modal-slide._show, .modal-popup._show {
    visibility: visible;
}

.modal-popup {
    z-index: 9000;
    left: 0;
    overflow-y: auto;
}

.modal-slide {
    left: 44px;
    z-index: 9000;
}

.modal-slide, .modal-popup {
    bottom: 0;
    left: 0;
    min-width: 0;
    position: fixed;
    right: 0;
    top: 0;
    visibility: hidden;
}


.modal-popup._inner-scroll .modal-inner-wrap {
    max-height: 90%;
}

.modal-popup._show .modal-inner-wrap {
    -webkit-transform: translateY(0);
    transform: translateY(0);
}

.modal-slide._inner-scroll .modal-inner-wrap {
    overflow-y: visible;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
}

.modal-slide._show .modal-inner-wrap {
    -webkit-transform: translateX(0);
    transform: translateX(0);
}

.modal-slide._show .modal-inner-wrap, .modal-popup._show .modal-inner-wrap {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
}

.modal-popup .modal-inner-wrap {
    margin: 5rem auto;
    width: 75%;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    box-sizing: border-box;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    -webkit-transform: translateY(-200%);
    transform: translateY(-200%);
    transition-duration: .2s;
    -webkit-transition-property: -webkit-transform, visibility;
    transition-property: transform, visibility;
    transition-timing-function: ease;
}

.modal-slide .modal-inner-wrap {
    height: 100%;
    overflow-y: auto;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    transition-duration: .3s;
    -webkit-transition-property: -webkit-transform, visibility;
    transition-property: transform, visibility;
    transition-timing-function: ease-in-out;
    width: auto;
}

.modal-slide .modal-inner-wrap, .modal-popup .modal-inner-wrap {
    background-color: #ffffff;
    box-shadow: 0 0 12px 2px rgba(0, 0, 0, 0.35);
    opacity: 1;
    pointer-events: auto;
}


@media screen and (min-width: 1024px) {
	.checkout-index-index .modal-popup .modal-inner-wrap {
		margin-left: -400px;
		width: 800px;
		left: 50%;
	}
}

.modal-slide._inner-scroll .modal-header, .modal-slide._inner-scroll .modal-footer {
    -webkit-flex-grow: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    flex-shrink: 0;
}

.modal-popup .modal-header {
    padding-bottom: 1.2rem;
    padding-top: 3rem;
}

.modal-popup .modal-header, .modal-popup .modal-footer {
    -webkit-flex-grow: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    flex-shrink: 0;
}

.modal-popup .modal-header, .modal-popup .modal-content, .modal-popup .modal-footer {
    padding-left: 3rem;
    padding-right: 3rem;
}

.modal-slide .modal-header {
    padding-bottom: 2.1rem;
    padding-top: 2.1rem;
}

.modal-slide .modal-header, .modal-slide .modal-content, .modal-slide .modal-footer {
    padding: 0 2.6rem 2.6rem;
}



.modal-popup._inner-scroll .modal-content {
    overflow-y: auto;
}


.modal-slide._inner-scroll .modal-content {
    overflow-y: auto;
}

.modal-popup .modal-header, .modal-popup .modal-content, .modal-popup .modal-footer {
    padding-left: 3rem;
    padding-right: 3rem;
}

.modal-slide .modal-header, .modal-slide .modal-content, .modal-slide .modal-footer {
    padding: 0 2.6rem 0.6rem;
}



.modal-custom .action-close, .modal-popup .action-close, .modal-slide .action-close {
    background-image: none;
    background: none;
    -moz-box-sizing: content-box;
    border: 0;
    box-shadow: none;
    line-height: inherit;
    margin: 0;
    padding: 0;
    text-shadow: none;
    font-weight: 400;
    display: inline-block;
    text-decoration: none;
    position: absolute;
    right: 0;
    top: 0;
}

.modal-custom .action-close:before, .modal-popup .action-close:before, .modal-slide .action-close:before {
    -webkit-font-smoothing: antialiased;
    font-size: 14px;
    line-height: 14px;
    color: #8f8f8f;
    content: '\f00d';
	font-family:$font-stack-icons;
    margin: 0;
    vertical-align: top;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
}

.modal-custom .action-close > span, .modal-popup .action-close > span, .modal-slide .action-close > span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}



.modal-slide .action-close {
    padding: 2.1rem 3rem;
}

.modal-popup .action-close {
    padding: 20px;
}



.modal-slide._inner-scroll .modal-footer {
    margin-top: auto;
}

.modal-slide._inner-scroll .modal-header, .modal-slide._inner-scroll .modal-footer {
    -webkit-flex-grow: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    flex-shrink: 0;
}

.modal-popup .modal-footer {
    margin-top: auto;
    padding-bottom: 1.5rem;
    padding-top: 1.5rem;
}

@media screen and (min-width: 769px) {
	.modal-popup.modal-slide .modal-footer {
		border-top: 1px solid #c1c1c1;
		text-align: right;
	}
}
