.toolbar__filter {
    font-family:$font-stack-special;
    text-transform:uppercase;
    font-size:em(20);
    position:relative;
    float:right;
    padding-right:em(30);
    .menu-icon {
        top:50%;
        margin-top:em(-12.5);
        right:em(0);
        height:em(25);
        width:em(25);
        padding:em(6);
        span {
            height:em(2.1);
        }
    }
}