.checkout-success {
	display:flex;
    width:100%;
    width:calc(100% + #{$contentColumnGutter});
    margin-left: -$contentColumnGutter / 2;
	&__item {
		flex:1;
        padding: 0 $contentColumnGutter / 2;
	}
    .block {
        margin-bottom:em(20);
        a:not(.button) {
            color: $body-color;
        }
        .block__title {
            padding:em(20);
            border:solid 1px $border-grey;
            border-bottom:solid 2px $border-red;
            @include linearGradient3;
            font-family: 'open_sansbold', sans-serif;
            font-weight: bold;
			position:relative;
            a {
                font-weight:normal;
            }
        }
        .block__content {       
            padding:em(20);
            border:solid 1px $border-grey;
            border-top:none;
		}
	}
}