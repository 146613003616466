.search.results {
	dl.block {
		margin: $gap--three $gap--four;
		.title{
			display:inline;
		}
		dd{
			display:inline;
			padding-left: 0;
			&:after{
				content:',';
				margin-left: -3px;
			}
			&:last-child{
				&:after{
					content:'';
				}
			}
		}
		a{
			color:$color-mortar;
			&:hover{
				color:$color-scarlet;
				text-decoration: underline;
			}
		}
	}
} // .intro