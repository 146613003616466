select {
    display:block;
    width:100%;
    background-color:$color-white-smoke;
    border:solid 1px $color-silver;
    @include roundedCorners(em(5));
    padding: em(9) em(15);
    box-shadow: inset 0 8px 10px -10px $color-silver;
    font-size:em($body-fontsize);
}

.control--select {
    position:relative;
    &:after {
        content:'\f078';
        font-family:FontAwesome;
        @include linearGradient2();
        @include roundedCorners(em(5));
        color:$color-white;
        position:absolute;
        right: 5px;
        top: 50%;
        line-height: em(34);
        width: em(34);
        margin-top:em(-17);
        pointer-events: none;
        font-size: 12px;
        text-align: center;
        z-index:100;
    }
    select {
        -webkit-appearance: none;
        -moz-appearance: none;
        text-overflow: '';
        appearance: none;
        z-index: 10;
        padding-right: em(38);
        position:relative;
        
        &::-ms-expand {
           display:none;
        }
        
        
        option:disabled {
            color:rgba(0,0,0,0.3);
        }
    }
}
.control--select--min {
    &:after {
        background:transparent;
        color:$color-scarlet;
    }
    &.control--select--small {
        &:after {
            right:em(0);
        }
        select {
            padding:em(4) em(30) em(4) em(10);
        }
    }
}
