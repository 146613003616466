.category-banner {
    .item + .item {
        display:none;
    }
}
    .category-banner__image {
        display:block;
    }
    
@media screen and (max-width: 700px) {
    .category-banner {
        display:none;
    }
}
