.grid--best-sellers {
	position: relative;
	padding: em(20);
	border: em(1) solid $border-grey;
	background: $color-white-smoke;
	@include roundedCorners(em(5));
	
	h3 {
		margin-bottom: em(7);
	} // h3
	
	.grid__list {
		width: calc(100% + 0.71428em);
		padding-left: 0;
		margin-left: em(-5);
	}
	
    .grid__item {
        width:100%;
        padding-left:5px;
        padding-right:5px;
		padding-bottom: 40px;
        &:nth-child(n+7) {
            display:none;
        }
    }
	
/* Override owl carousel defaults
---------------------------------------*/
	.owl-carousel .owl-nav {
		.owl-prev { left: em(-19)!important; } 
		.owl-next { right: em(-19)!important; } 
	}
} // .grid--best-sellers 