h3, .h3 {
    font-size: em(24);
    line-height:1.2em;
    font-family:$font-stack-special;
    color:$typography-heading-color;
    a {
        color:inherit;
    }
}

@media screen and (max-width: 700px) {
    h3, .h3 {
        font-size:5.5vw;
    }
}