.knowledge-base {
	position: relative;
	padding: em(20);
	min-height: 275px;
	border: em(1) solid $border-grey;
	background: $color-white-smoke;
	@include roundedCorners(em(5));
	
	
/* Top heading and link
---------------------------------------*/
	
	h3 {
		margin-bottom: 0;
	} // h3
	
	h5 {
		font-family: $font-stack-default;
		font-weight: 700;
		margin-top: em(14);
		font-size: em($body-fontsize - 2);
		color: $color-mortar;
	} // h5
	
	&__link {
		color: $color-scarlet;
		font-weight: 700;
		display: inline-block;
		margin-top: em(15);
		
		@media screen and (min-width: 380px){
			margin-top: 0;
			position: absolute;
			top: em(20);
			right: em(20);
		}
		
		.fa {
			font-family: $font-stack-icons;
			font-size: em(9);
			padding-left: em(10);
		}
	} // &__link
	
	
/* Blog article/excerpt content
---------------------------------------*/
	
	&__image {
		margin: em(20) 0;
		width: 100%;
	} // &__image
	
	&__content {
	} // &__content
	
	&__excerpt {
		@media screen and (min-width: 380px){
			vertical-align: top;
			padding-right: 27%;
		}
		
		a {
			color: $color-scarlet;
			font-weight: 700;

			.fa {
				font-family: $font-stack-icons;
				font-size: em(9);
				padding-left: em(10);
			}
		} // a
	} // &__excerpt
	
	&__title {
		@include clearfix;
		
		@media screen and (min-width: 380px){
			margin-bottom: em(5);
		}
		
		h4 {
			font-family: $font-stack-default;
			font-size: em($body-fontsize);
			font-weight: 700;
			
			@media screen and (min-width: 380px){
				float: left;
				width: 70%;
			}

			& + p {
				margin-top: em(8);
			}
		} // h4
	} // &__title
	
	&__meta {
		color: $color-silver;
		margin: em(5) 0;
		
		@media screen and (min-width: 380px){
			float: right;
			width: 27%;
			margin: 0;
			text-align: right;
		}
	} // &__meta
	
	
	
	
} // .knowledge-base