.imp-height {
    position: relative;
    height: 0;
    overflow: hidden;
}

.imp-height > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}