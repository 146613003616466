@font-face {
    font-family: 'open_sans_condensedbold';
    src: url('../fonts/OpenSansCondensed/OpenSans-CondBold-webfont.eot');
    src: url('../fonts/OpenSansCondensed/OpenSans-CondBold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/OpenSansCondensed/OpenSans-CondBold-webfont.woff') format('woff'),
         url('../fonts/OpenSansCondensed/OpenSans-CondBold-webfont.ttf') format('truetype'),
         url('../fonts/OpenSansCondensed/OpenSans-CondBold-webfont.svg#open_sans_condensedbold') format('svg');
    font-weight: normal;
    font-style: normal;

}