html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video, button {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}
html {
    -webkit-text-size-adjust: 100%;
	height:100%;
}
body {
	line-height: 1;
	height:100%;
}
ol, ul, li {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: seperate;
	border-spacing: 0;
}
input {
    text-align:left;
    margin:0;
}
button {
    text-align:left;
    margin:0;
    &::-moz-focus-inner {
        padding: 0;
        border: 0
    }
}
a {
    text-decoration:none;
}
.no-display {
    display:none !important;
}

img {
    max-width:100%;
    height:auto;
}

table > caption,
.fieldset > .field.no-label > .label,
.fieldset > .fields > .field.no-label > .label, 
.product-item-actions .actions-secondary > .action span, 
.table-comparison .cell.label.remove span, 
.table-comparison .cell.label.product span, 
.cart.table-wrapper .col.qty .label, 
.minicart-wrapper .action.showcart .text, 
.minicart-wrapper .action.showcart .counter-label, 
.checkout-index-index .page-title-wrapper, 
.checkout-payment-method .field-select-billing > .label, 
.checkout-payment-method .payments .legend, 
.checkout-payment-method .ccard .legend, 
.account .column.main .limiter > .label, 
.field.street .field.additional .label, 
.account-nav .title, 
.page-product-downloadable .product-options-wrapper .fieldset .legend.links-title, 
.page-product-giftcard .fieldset.giftcard > .legend, 
.opc-wrapper .form-giftcard-account .field .label, 
.filter-options-content .filter-count-label, 
.block-wishlist-search-form .form-wishlist-search .fieldset > .legend, 
.page-multiple-wishlist .page-title-wrapper .page-title, 
.multicheckout .table-wrapper .col .label, 
.multicheckout .block .methods-shipping .item-content .fieldset > .legend, 
.block.newsletter .label, .block-reorder .product-item .label, 
.opc-wrapper .form-discount .field .label, 
.action.skip:not(:focus), 
.page-header .switcher .label, 
.page-footer .switcher .label, 
.products-grid.wishlist .product-item .comment-box .label {
    @include visible();
}