.opc .checkout-payment-method {
    margin:0;
}
#checkout-step-payment {
    padding:0;
    border:none;
    .payment-method {
        padding:em(20);
        border:solid 1px $border-grey;
        border-top:none;
    }
    .payment-method-content {
		display:none;
        padding:em(20);
        margin-top:em(20);
        border-top:solid 1px $border-grey;
    }
    ._active .payment-method-content {
		display:block;
    }
    .billing-address-same-as-shipping-block {
        margin-bottom:em(20);
    }
    .payment-method-billing-address {
        .actions-toolbar {
            margin-top:em(20);
            border:none;
            padding:0;
        }
    }
	.payment-method-title .label{
		display:block;
	}
	.payment-method-title .label .payment-icon{
		+ span{
			display:none;
		}
		& span{
			display:none;
		}
	}
}

.field.street .field label {
  display: none;
}

#co-transparent-form-braintree {
    .fieldset > .field {
        .braintree-card-control {
            img.braintree-credit-card-selected {
                width: 45px;
            }
            #braintree_cc_number {
                display: inline-block;
                height: 45px;
                padding-left: 5px;
            }
        }
        .control {
            div {
                #braintree_expirationDate {
                    height: 45px;
                }
            }
            #braintree_cc_cid {
                height: 45px;
            }
            .field-tooltip {
                left: 100px;
                .field-tooltip-action {
                    &:before {
                        font-size: 15px;
                    }
                }
            }
        }
        div {
            ul {
                padding: 0;
                li.item {
                    display: inline-flex;
                    padding: 0;
                    img {
                        width: 35px;
                    }
                    &:before {
                        display:none;
                    }
                }
            }
        }
    }
}

