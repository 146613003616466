.deal { 
	position: relative;
	padding: em(20);
	min-height: 275px;
	border: em(1) solid $border-grey;
	background: $color-white-smoke;
	
	@media screen and (min-width: 380px){
		padding-right: em(190);
	}
	
	@media screen and (min-width: 1200px){
		padding-right: em(210);
	}
	
	h3 {
		margin-bottom: em(5);
	}
	
	ul {
		font-size: em($body-fontsize - 2);
		padding-left: 0;
		
		li {
			position: relative;
			padding-left: em(15);
			
			a {
				color: inherit;
				&:hover {
					text-decoration: underline;
				}
			}
			// Bullet
			&::before {
				content: "";
				position: absolute;
				left: 0;
				top: em(10);
				display: block;
				width: 4px;
				height: 4px;
				background: $color-scarlet;
				@include roundedCorners(50%);
			} // &::before
		} // li
	} // ul
	.button {
		margin-top: em(20);
	}
	
	// Top brand logo
	&__brand {
		margin-bottom: em(15);
	} // &__brand
	
	// Right large image
	&__image {
		position: absolute;
		right: 20px;
		top: 20px;
		display: none;
		max-width: em(140);
		
		@media screen and (min-width: 380px){
			position: absolute;
			max-width: em(180);
			right: 20px;
			top: 20px;
			display: block;
		}
	} // &__image
	
	// WTG logo
	&__logo {
		margin-top: em(20);
	} // &__logo
	
} // .deal