.product-addthis {
    margin-top:0;
	text-align: right;
}
.product-addthis__label {
    display:inline-block;
    vertical-align:middle;
    font-family:$font-stack-special;
    color:$typography-heading-color;
}
.product-addthis__control {
    display:inline-block;
    vertical-align:middle;
}