.product.attribute {
    padding:em(15) em(0);
    border-top:solid 1px $border-grey;
    &.sku {
        border-top:none;
        letter-spacing:em(-4);
        * {
            letter-spacing:normal;
        }
        .type {
            margin-right:em(5);
        }
        .value {
            display:inline-block;
        }
    }
    &.delivery {
        .value {
            padding-right:em(30);
            position:relative;
            line-height:em($body-fontsize + 3);
            &:after {
                content:'\f0d1';
                font-family:FontAwesome;
                color:$color-scarlet;
                position:absolute;
                top:em(0);
                right:em(0);
                font-size:em($body-fontsize + 3);
            }
        }
    }
}

.product.media {
    &+.product.attribute {
        border-top:none;
    }
}

