
@mixin rem($pixels: $browser-context, $attr: font-size, $important: false, $debug: null) {

	$fallback: true;

	@if($debug)
	{
		@debug "=== BEGIN ===";
	}

	$px: ();
	$rem: ();

	@if type_of($pixels) == 'list'
	{
		@each $pixel in $pixels
		{
			@if $pixel == 0 or $pixel == 'auto'
			{
				$px: append($px, $pixel);
				$rem: append($rem, $pixel);
			}
			@else
			{
				@if type_of(str-index(inspect($pixel), 'px')) == 'null' {
					$px: $pixel+'px';
				}
				$px: append($px, $pixel);
				$rem: append($rem, str-replace(em($pixel, $browser-context),'px','rem'));
			}
		}
	}
	@else
	{
		@if($debug)
		{
			@debug $pixels;
		}
		$px: $pixels;
		@if type_of(str-index(inspect($px), 'px')) == 'null' {
			$px: $px+'px';
		}
		$rem: str-replace($pixels/$browser-context, 'px', 'rem');
		@if type_of(str-index(inspect($rem), 'rem')) == 'null' {
			$rem: $rem+'rem';
		}
	}

	@if($important)
	{
		$px: $px+' !important';
		$rem: $rem+' !important';
	}

	@if($fallback)
	{
		#{$attr}:#{$px};
	}
	#{$attr}:#{$rem};

	@if($debug)
	{
		@debug "#{$attr}:#{$px}";
		@debug "#{$attr}:#{$rem}";
		@debug "=== END ===";
	}

}
