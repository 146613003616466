.form-list {
    padding:0px;
}
.form-list__fields {
    &+.form-list__fields {
        margin-top:em(20);
    }
}
.form-list__fields--half {
    @include clearfix;
    width:100%;
    width:calc(100% + 20px);
    margin-left:-10px;
    margin-top:em(20);
    .form-list__field {
        float:left;
        width:50%;
        padding: em(0) em(10);
    }
}
.form-list__fields--nested {
    .form-list__field + .form-list__field {
        margin-top:em(20);
    }
}
.fieldset {
    &> .field > .label, 
    &.fields > .field > .label {
        display:inline-block;
        margin-bottom:em(7.5);
    }
    &> .field,
    &> .fields > .field {
        margin-bottom:em(15);
    }
    &> .field:last-child {
        margin-bottom:0;
    }
    &> .field .additional,
    &> .fields > .field .additional {
        margin-top:em(15);
    }

    .field--size-phone{
        max-width: 180px;
    }

    .field--size-postcode{
        max-width: 120px;
    }

    .field--size-normal{
        max-width: 300px;
    }

    .field--size-country{
        max-width:250px;
    }

    .field--note{
        padding: 0.71429em;
        margin-bottom: 0.71429em;
        background-color: #f2f2f2;
        border: solid 1px #e7e7e7;
    }
}

.actions-toolbar {
    padding-top:30px;
    margin-top:20px;
    border-top:solid 1px #e2e2e2;
    @include clearfix;
    .primary {
        width:50%;
        float:right;
        text-align:right;
    }
    .secondary {
        width:50%;
        float:left;
        text-align:left;
    }
}

.primary--centre{
    text-align: center;
}


.fancybox-outer, .fancybox-inner {
    min-width:400px;
}

@media screen and (max-width: 500px) {
    .fancybox-outer, .fancybox-inner {
        min-width: auto;
        width:100%;
    }
}