.divider {
	width: 100%;
	height: 2px;
	background: $color-scarlet;
	margin: em(40) 0;
	
	
/* Heading variant
---------------------------------------*/
	&--heading {
		margin: 16px 0; margin: 1rem 0;
		background: none;
		height: auto;
		line-height: 2;
		
		h2 {
			overflow: hidden;
			text-align: center;
			
			span {
				display: inline-block;
				vertical-align: middle;
			}
		}
		h2:before,
		h2:after {
			background-color: $color-scarlet;
			content: "";
			display: inline-block;
			height: 2px;
			position: relative;
			vertical-align: middle;
			width: 50%;
		}
		h2:before {
			right: 0.5em;
			margin-left: -50%;
		}
		h2:after {
			left: 0.5em;
			margin-right: -50%;
		}
	} // &--heading
} // .divider
