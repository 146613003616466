.product-features {
    margin-top:em(20);
    width:100%;
    table-layout:fixed;
    tr {
        &:nth-child(odd){
            td {
                background-color:$color-white-smoke;
            }
        }
        &:nth-child(even){
            td {
                background-color:$color-white;
            }
        }
    }
    td {
        width:50%;
        border:solid 1px $border-grey;
        padding:em(7.5);
        font-size:em($body-fontsize - 1);
        line-height:em($body-fontsize + 3);
    }
}

@media screen and (max-width: 550px) {
    .product-features {
        display:block;
        tbody {
            display:block;
        }
        tr {
            display:block;
            &+tr td {
                border-top:none;
            }
            
        }
        td {
            display:block;
            width:100%;
            &:nth-child(1) {
                border-bottom:none;
                font-weight:bold;
                padding-bottom:em(2);
                font-family:$font-stack-default--bold;
                color:$typography-heading-color;
            }
            &:nth-child(2) {
                padding-top:em(2);
                border-top:none;
            }
        }
    }

}
