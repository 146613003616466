ol {
    padding-left: em(20);
    counter-reset: section;  
    ol {
        padding-left: em(0)
    }
    li {
        line-height:em(22);
        padding-left:em(32);
        position:relative;
        &>ol,
        &+li {
            margin-top:em(12);
        }
        &:before {
            counter-increment: section;
            content: counters(section,".") " ";
            position:absolute;
            top:em(0);
            left:em(0);
            display:block;
            font-size:em(10);
            width:em(35);
            line-height:em(35);
            text-align:center;
            color:$color-white;
            background-color:$color-scarlet;
            @include roundedCorners(em(20));
        }
    }
}