// ==========================================================================================
// PLAIN TABS
// ==========================================================================================

.tabbed { 
    position: relative;
    font-size: em($body-fontsize);
    margin-bottom:em(20);
    clear:both;

    .tabbed-nav {
        padding:em(0);
        display: table;
        table-layout: fixed;
        margin: 0;
        position: relative;
        width: 100%;
        & > .tab-nav-bar-wrap > li,
        & > li { 
            display: table-cell;
            text-align: center;
            margin: 0;
            padding: 0px;
            vertical-align: bottom;
            position: relative;
            z-index: 40;
            font-family:$font-stack-special;
            font-size:em($body-fontsize + 3);
            border-left:solid 1px $color-charcoal;
            border-right:solid 1px $color-black;
            &:first-child {
                border-left-color:$color-black;
                &.active {
                    border-left-color: $border-grey;
                }
            }
            &:last-child {
                border-right-color:$color-black;
                &.active {
                    border-right-color:$border-grey;
                }
            }

            a {
                display:table;
                width:100%;
                border-spacing:0px;
                position: relative;
                @include linearGradient2();
                color: #fff;
                &:hover {
                    text-decoration: none;
                }
                span {
                    display:table-cell;
                    padding: 0px 10px;
                    vertical-align: middle;
                    height:50px;
                    text-align: center;
                    position:relative;
                }
            }

            &.active {
                z-index: 60;
                display: table-cell;
                position: relative;
                
                a { 
                    position: relative;
                    background: $color-white;
                    color:$typography-heading-color;
                    &:before {
                        content:'';
                        display:block;
                        width:100%;
                        position:absolute;
                        top:0px;
                        left:0px;
                        height:2px;
                        background-color:$color-scarlet;
                    }
                } // a
            }
        } // li
        &.no-display + .tabbed-content-wrap {
            border-top:solid 1px $border-grey;
        }
    } // .tabbed-nav

    .tabbed-content-wrap {
        padding:em(0);
        margin:em(0);
        position: relative;
        z-index: 50;
        overflow: hidden;
    } // .tabbed-content-wrap

    .tabbed-content {
        margin:em(0);
        padding: em(20);
        background:$color-white;
        display: none;
        z-index: 50;
        max-width: 100%;
        border:solid 1px $border-grey;
        border-top:none;


        &.active {
            display: block;
        }

        h1 {
            text-transform: none;
        }
    } // > div
} // tabbed

.advantecTabs-accordion {
    .tabbed {
        .tabbed-nav {
            display:block;
            .tab-nav-bar-wrap {
                border-top:solid 1px $color-charcoal;
                border-bottom:solid 1px $color-black;
                &:first-child {
                    border-top:none;
                }
                &:last-child {
                    border-bottom:none;
                }
                &> li {
                    display:block;
                    text-align:left;
                    border:none;
                    &> a {
                        display:block;
                        background:$color-gondola;
                        span {
                            display:inline-block;
                            vertical-align:middle;
                            width:100%;
                            text-align:left;
                            padding-left:em(20);
                            padding-right:em(40);
                            position:relative;
                            &:before {
                                content:'';
                                display:inline-block;
                                vertical-align:middle;
                                height:100%;
                            }
                            &:after {
                                content:'\f067';
                                font-family:FontAwesome;
                                position:absolute;
                                right:em(20);
                                top:50%;
                                @include transition();
                                font-size:em($body-fontsize - 2);
                                margin-top:em(-$body-fontsize);
                            }
                        }
                    }
                    &.active {
                        &>a {
                            color:$color-white;
                            &:before {
                                display:none;
                            }
                            span {
                                &:after {
                                    @include rotate(45deg);
                                }
                            }
                        }
                    }
                }
            } 
        }
    }
}