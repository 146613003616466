.checkout-index-index {
    #maincontent {
		padding-top: 0;
		flex-grow: 1;
		flex-shrink: 0;
		flex-basis: auto;
        &>.wrapper {
            border-top:solid 4px $border-red;
        }
    }
    .page-title__wrapper {
        border:solid 1px $border-grey;
        padding:em(20);
        margin-bottom:em(20);
    }
    .columns {
        .column.main {
            padding-right:0;
        }
        .opc-wrapper {
            float:left;
            width:100%;
            padding-right:345px;
        }
    }

    div[name$=".country_id"]::after{
        display:block;
        content:"Currently only billing/shipping in the UK is available";
        padding-top: 5px;
        color:$color-scarlet;
    }
}

@media screen and (max-width: 830px) {
	.checkout-index-index {
		.columns {
			.opc-wrapper {
				padding-right:0px;
				float:none;
			}
		}
	}

}