.filter-current + .filter-options {
    margin-top:em(20);
}

.filter__options-title {
    padding:em(20);
    border:solid 1px $border-grey;
    span {
        display:block;
        position:relative;
        padding-right:em(15);
        @include transition();
        &:after {
            content:'\f067';
            font-family:FontAwesome;
            color:$color-scarlet;
            position:absolute;
            font-size:em(10);
            line-height:em(10);
            right:em(0);
            top:50%;
            margin-top:em(-5);
            @include transition();
        }
    }
    &:hover {
        cursor:pointer;
    }
}
    .filter__options-title--active {
        border-bottom:solid 2px $color-scarlet;
        span:after {
            @include rotate(45deg);
        }
    }
    
.filter__options-content {
    background-color:$color-white-smoke;
    padding:em(20);
    &.no-display + .filter__options-title {
        border-top:none;
    }
}
