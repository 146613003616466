.opc {
    padding:0;
    counter-reset: opc-step;
    &>li {
        padding:0;
        &:before {
            display:none;
        }
        &+li {
            margin-top:em(20);
        }
    }
    .step-title {
        padding:em(20);
        border:solid 1px $border-grey;
        border-bottom:solid 2px $border-red;
        @include linearGradient3;
		.h3 {
			margin-right:5px;
		}
		.note {
			margin-top:em(5);
			font-size: em($body-fontsize - 2);
		}
    }
    .step-content {
        padding:em(20);
        border:solid 1px $border-grey;
        border-top:none;
    }
}
.opc-wrapper {
    .form-login, 
    .form-shipping-address,
    #checkout-shipping-method-load,
    .billing-address-form {
        max-width:500px;
    }
}