.account {
    .block {
        margin-bottom:em(20);
        a:not(.button) {
            color: $body-color;
        }
        .block-title {
            padding:em(20);
            border:solid 1px $border-grey;
            border-bottom:solid 2px $border-red;
            @include linearGradient3;
            font-family: 'open_sansbold', sans-serif;
            font-weight: bold;
			position:relative;
            a {
                font-weight:normal;
            }
        }
		.block-title-actions{
			position:absolute;
			right:em(20);
			top:50%;
			transform:translateY(-50%);
		}
        .block-content {
            padding:em(20);
            border:solid 1px $border-grey;
            border-top:none;
            ul, ol {
                padding-left: 0;
            }
            .box {
                .box-content {
                    margin:em(10) 0;
                    address {
                        line-height:1.3;
                    }
                }
            }
            .field {
                & + .field {
                    margin-top:em(20);
                }
                .nested {
                    margin-top:em(20);
                }
            }
        }
        .form-cart {
            padding-right:0;
            float:none;
        }
        .table-wrapper {
			.subtotal{
				text-align:right;
			}
        }
    }
    .order-links {
        margin-bottom:em(10);
        li {
            display: inline-block;
            a {
                color: $body-color;
				padding: 0 em(10);
            }
            a:hover {
				text-decoration: underline;
            }
            & + li {
                margin-left: em(10);
            }
        }
    }
    .page-title-wrapper {
        .page-title {
            display:inline-block;
        }
        .order-status {
            display:inline-block;
            margin-top:0;
        }
        .actions {
            a {
                color: $body-color;
            }
        }
    }
}


.form-cart{
	.cart.table thead.cart__head--alt th{
		font-family: $font-stack-default--bold;
		color:#fff;
		background:$color-charcoal;
		border:none;
		padding-top:em(10);
		padding-bottom:em(10);
	}

	.cart-totals{
		margin-top:0px;
		padding:em(10);
		border: solid 1px $color-gainsboro;
		border-top: none;
		table th{
			padding-left:em(13);
			padding-right:em(13);
		}
	}
}

.customer-account-forgotpassword,.customer-account-createpassword, .customer-account-create{
    .account{
        overflow: hidden;
        display: flex;
        justify-content: center;
    }
}

.password-strength-meter {
    background-color: #f4f4f4;
    line-height: 32px;
    padding: 0 9px;
    position: relative;
    z-index: 1;

	&:before {
		content: '';
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		z-index: -1;
	}
}


.password-strength-meter-1 .password-strength-meter:before {
    background-color: #ffafae;
    width: 25%;
}

.password-strength-meter-2 .password-strength-meter:before {
    background-color: #ffd6b3;
    width: 50%;
}

.password-strength-meter-3 .password-strength-meter:before {
    background-color: #c5eeac;
    width: 75%;
}

.password-strength-meter-4 .password-strength-meter:before {
    background-color: #81b562;
    width: 100%;
}

.page-print .header-logos{
	display:block;
	float:none;
	padding-bottom:em(20);
}
.sales-order-view .items.order-links{
    padding-top: 15px;
    padding-left:0px;

}
.sales-order-view .actions-toolbar{
    padding-top:15px;
}
.account .page-title-wrapper .actions a{
    color: #fff;
}
/* overwrite parallax styles */
div.category-description div div[data-element="inner"] { padding: 0 ; margin: 0  }
div.category-description div.category-description{border: 0px;padding: 0px;margin-top: 10px;
    margin-left: -10px;}
