.filter__options-list {
    padding:em(0);
}
.filter__options-item {
    padding:em(0);
    &:before {
        display:none;
    }
    &+.filter__options-item {
        margin-top:em(5);
    }
    a {
        text-decoration:none;
        color:$body-color;
        &:hover {
            cursor:pointer;
            color:$color-scarlet;
        }
    }
    span {
        @include opacity(0.3);
    }
}