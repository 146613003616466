.control.qty {
    position:relative;
    input {
        padding-right:em(30);
    }
}
input.qty {
    max-width:em(75);
}
.qty__button {
    width:em(24);
    display:block;
    position:absolute;
    right:0px;
    border:solid 1px $border-grey--dark;
    overflow:hidden;
    @include linearGradient3();
    &:after{
        content:'';
        display:block;
        width:100%;
        text-align:center;
        top:50%;
        transform: translateY(-50%);
        position:absolute;
        display:block;
        vertical-align:middle;
        font-family:FontAwesome;
        font-size:em($body-fontsize - 5);
        color:$color-scarlet;
    }
    &:hover {
        cursor:pointer;
    }
}
.qty__button--plus {
    top:0px;
    bottom:50%;
    @include roundedCorners(0 em(5) 0 0);
    &:after{
        content:'\f067';
    }
}
.qty__button--minus {
    top:50%;
    bottom:0px;
    @include roundedCorners(0 0 em(5) 0);
    margin-top:-1px;
    &:after{
        content:'\f068';
    }
}