.cart-summary {
    float:right;
    width:em(320);
    margin-left:em(-320);
    z-index:2;
    position:relative;

    .field[name="shippingAddress.region"] {
        display: none;
    }
}

.cart-summary__title {
    padding:em(20);
    border:solid 1px $border-grey;
    border-bottom:solid 2px $border-red;
    @include linearGradient3();
	.note {
		margin-top:em(5);
        font-size: em($body-fontsize - 2);
	}
}
.cart-summary__content {
    padding:em(20);
    background-color:$color-white;
    border:solid 1px $border-grey;
    border-top:none;
    .block {
        &+.block {
            margin-top:em(20);
            padding-top:em(20);
            border-top:solid 1px $border-grey;
        }
    }
    .fieldset {
        &.estimate {
            .field {
                .control {
                    margin-top:em(5);
                }
                &+.field {
                    margin-top:em(20);
                }
            }
        }
        &.rate {
            margin-top:em(20);
            .item-options {
                .field {
                    &:first-child {
                        margin-top:em(5);
                    }
                    &+.field {
                        margin-top:em(15);
                    }
                }
                &+.item-title {
                    margin-top:em(20);
                }
            }
            .label {
                font-family:$font-stack-default;
            }
        }
    }
}

@media screen and (max-width: 1200px) {
    .cart-summary {
        width:em(275);
        margin-left:em(-275);
    }
}
@media screen and (max-width: 700px) {
    .form-cart + .cart-summary {
        margin-top:em(20);
    }
    .cart-summary {
        float:none;
        width:auto;
        margin:0;
        margin-bottom: em(24);
    }
}
