// Images
//@import 'datauri/png';
//@import 'datauri/svg';

// Functions
@import 'functions/pxtoem';

// Mixins
@import 'mixins/em';
@import 'mixins/breakpoints';
@import 'mixins/clearfix';
@import 'mixins/roundedCorners';
@import 'mixins/linearGradient';
@import 'mixins/svg-bg-fallback';
@import 'mixins/transform';
@import 'mixins/transition';
@import 'mixins/transitionDelay';
@import 'mixins/transitionDuration';
@import 'mixins/transitionProperty';
@import 'mixins/rotate';
@import 'mixins/opacity';
@import 'mixins/visible';

// Global
@import 'globals/reset';
@import 'globals/variables';
@import 'globals/global';
@import 'globals/structure';

/* Fonts */
@import 'fonts/opensanscondensed';
@import 'fonts/opensans';

/**
 * PLugins
 */
@import 'plugins/impheight';
@import 'plugins/owl-carousel';
@import 'plugins/fancybox';
@import 'plugins/advantec.tabs';
@import 'plugins/ampromo';
@import 'plugins/searchsuiteautocomplete';

/**
 * Elements
 */
@import './patterns/elements/typography/h1/default';
@import './patterns/elements/typography/h2/default';
@import './patterns/elements/typography/h3/default';
@import './patterns/elements/typography/h4/default';
@import './patterns/elements/typography/h5/default';
@import './patterns/elements/typography/h6/default';
@import './patterns/elements/typography/strong/default';
@import './patterns/elements/lists/definition-list/default';
@import './patterns/elements/lists/ordered-list/default';
@import './patterns/elements/lists/unordered-list/default';
@import './patterns/elements/forms/input/default';
@import './patterns/elements/forms/input~qty/default';
@import './patterns/elements/forms/textarea/default';
@import './patterns/elements/forms/label/default';
@import './patterns/elements/forms/select/default';
@import './patterns/elements/forms/tooltip/default';
@import './patterns/elements/dividers/divider/default';
@import './patterns/elements/buttons/video-play/default';
@import './patterns/elements/buttons/button/default';
@import './patterns/elements/catalog/product-addthis/default';
@import './patterns/elements/catalog/product-features/default';

/**
 * Components
 */
@import './patterns/components/global/typography/default';
@import './patterns/components/global/input-checkbox/default';
@import './patterns/components/global/input-radio/default';
@import './patterns/components/global/input-button/default';
@import './patterns/components/global/forms/default';
@import './patterns/components/page/breadcrumb/default';
@import './patterns/components/page/breadcrumb-alt/default';
@import './patterns/components/page/message/default';
@import './patterns/components/page/content/default';
@import './patterns/components/header/header/default';
@import './patterns/components/header/basket/default';
@import './patterns/components/header/search/default';
@import './patterns/components/header/account/default';
@import './patterns/components/header/header-enclosed/default';
@import './patterns/components/navigation-main/menu/default';
@import './patterns/components/footer/footer/default';
@import './patterns/components/footer/footer-enclosed/default';
@import './patterns/components/home/banner/default';
@import './patterns/components/home/categories/default';
@import './patterns/components/home/product-slider/default';
@import './patterns/components/home/deal/default';
@import './patterns/components/home/blog/default';
@import './patterns/components/home/twitter-latest/default';
@import './patterns/components/home/promo-tile/default';
@import './patterns/components/home/promo-tile-banner/default';
@import './patterns/components/home/intro/default';
@import './patterns/components/layered-navigation/filter/default';
@import './patterns/components/layered-navigation/state/default';
@import './patterns/components/layered-navigation/view/default';
@import './patterns/components/catalog/description/default';
@import './patterns/components/catalog/banner/default';
@import './patterns/components/catalog/price/default';
@import './patterns/components/catalog/list/default';
@import './patterns/components/catalog/navigation/default';
@import './patterns/components/catalog/product-gallery/default';
@import './patterns/components/catalog/product-attribute/default';
@import './patterns/components/catalog/product-info/default';
@import './patterns/components/catalog/quick-view/default';
@import './patterns/components/catalog/product-addtocart/default';
@import './patterns/components/catalog/product-options/default';
@import './patterns/components/catalog/product-best-sellers/default';
@import './patterns/components/catalog/product-list/default';
@import './patterns/components/catalog/product-list~crosssell/default';
@import './patterns/components/catalog/product-list~related/default';
@import './patterns/components/catalog/product-list~bought/default';
@import './patterns/components/checkout/cart-coupon/default';
@import './patterns/components/checkout/cart-summary/default';
@import './patterns/components/checkout/cart-totals/default';
@import './patterns/components/checkout/cart-methods/default';
@import './patterns/components/checkout/cart-form/default';
@import './patterns/components/checkout/opc/default';
@import './patterns/components/checkout/opc-login/default';
@import './patterns/components/checkout/opc-shipping/default';
@import './patterns/components/checkout/opc-shipping-logedin/default';
@import './patterns/components/checkout/opc-shipping-logedin/address';
@import './patterns/components/checkout/opc-shipping-methods/default';
@import './patterns/components/checkout/opc-payment-methods/default';
@import './patterns/components/checkout/opc-summary/default';
@import './patterns/components/checkout/opc-summary-shipping/default';
@import './patterns/components/checkout/opc-success/default';
@import './patterns/components/toolbar/amount/default';
@import './patterns/components/toolbar/limiter/default';
@import './patterns/components/toolbar/pager/default';
@import './patterns/components/toolbar/sorter/default';
@import './patterns/components/toolbar/view/default';
@import './patterns/components/toolbar/filter/default';
@import './patterns/components/blog/post/default';
@import './patterns/components/search/suggest/default';

/**
 * Templates
 */
@import './patterns/templates/category/default';
@import './patterns/templates/product/default';
@import './patterns/templates/basket/default';
@import './patterns/templates/checkout/default';
@import './patterns/templates/home/default';
@import './patterns/templates/account/default';

/**
 * Modules
 */

@import '../../Magento_Search/web/css/source/module';