@mixin visible($show:null) {
    @if $show {    
        border: 0;
        clip: initial;
        height: auto;
        margin: 0;
        overflow: initial;
        padding: 0;
        position: static;
        width: auto;
    } @else {
        border: 0;
        clip: rect(0, 0, 0, 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }
}
