.field .control {
    &._with-tooltip--demo {
        display:inline-block;
        height:40px;
        padding-right:em(40);
    }
    &._with-tooltip {
        position:relative;
        input[type="text"],
        input[type="number"],
        input[type="tel"],
        input[type="email"] {
            width:calc(100% - #{em(40)});
        }
    }
}

.field-tooltip {
    cursor: pointer;
    position: absolute;
    right: 0;
    top:7px;
    .label {
        display: none;
    }
    .field-tooltip-action {
        display: inline-block;
        text-decoration: none;
        &:before {
            content:'\f29c';
            font-family:FontAwesome;
            font-size:em(30);
            color:$color-silver;
            @include transition();
        }
    }
    .field-tooltip-content {
        background: $color-white-smoke;
        border: 1px solid $border-grey;
        border-radius: 1px;
        font-size: em($body-fontsize);
        padding: em(10);
        width: 300px;
        display: none;
        line-height:em($body-fontsize + 4);
        left: em(40);
        position: absolute;
        text-transform: none;
        top: -7px;
        word-wrap: break-word;
        z-index: 2;
        &:before,
        &:after {
            border: 10px solid transparent;
            height: 0;
            width: 0;
            border-right-color: $color-white-smoke;
            left: -21px;
            top: 9px;
            content: '';
            display: block;
            position: absolute;
            z-index: 3;
        }
        &:before {
            border-right-color:$border-grey;
        }
        &:after {
            border-right-color: $color-white-smoke;
            left:-19px;
            z-index: 4;
        }
    }
    &._active,
    &:hover {
        .field-tooltip-action:before {
            color:$color-gondola;
        }
    }
    &._active {
        .field-tooltip-content {
            display:block;
        }
    }
}

@media screen and (max-width: 830px) {
    .field-tooltip .field-tooltip-content {
        right: em(-7.5);
        top: em(45);
        left: auto;
        &:before,
        &:after {
            border: 10px solid transparent;
            height: 0;
            width: 0;
            margin-top: -21px;
            right: 10px;
            left: auto;
            top: 0;
        }
        &:before {
            border-bottom-color:$border-grey;
        }
        &:after {
            border-bottom-color: $color-white-smoke;
            top:1px;
        }
    }
}