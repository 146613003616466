textarea {
    display:block;
    width:100%;
	height:8em;
    background-color:$color-white-smoke;
    border:solid 1px $color-silver;
    @include roundedCorners(em(5));
    padding: em($form-input-padding-vertical) em($form-input-padding-horizontal);
    box-shadow: inset 0 8px 10px -10px $color-silver;
    font-size:em($body-fontsize);
}