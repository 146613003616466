.twitter-latest {
	position: relative;
	background: $color-dodger-blue;
	padding: em(20) em(10) em(10) em(20);
	@include roundedCorners(em(5));

	@media screen and (min-width: 380px) {
		padding-left: 145px;
		padding-top: em(10);
	}

	h3 {
		color: $color-white;
		margin-bottom: em(10);
		font-size: em($body-fontsize + 10);

		@media screen and (min-width: 380px) {
			position: absolute;
			left: em(10);
		}
	}

	.tweet_list{
		padding-left:0;

		> li {
			+ li {
				margin-top: 2.4em;
			}
		}
	}

	// Twitter bird icon
	&::before {
		content: "\f099";
		display: block;
		position: absolute;
		background-size: cover;
		right: em(10);
		font-family: $font-stack-icons;
		font-size: em(28);
		line-height: 1;
		color: $color-columbia-blue;

		@media screen and (min-width: 380px) {
			top: auto;
			right: auto;
			bottom: 0px;
			left: 10px;
			font-size: 130px;
		}
	} // &::before

	&__body {
		padding: em(10);
		@include roundedCorners(em(5));
		background: $color-white;
		position: relative;

		max-height: 20em;
    	overflow: auto;

		@media screen and (min-width: 380px) {
			padding: em(20);
			min-height: em(130);
		}

		// Left-pointing triangle
		&::before {
			content: "";
			position: absolute;
			display: block;
			border-top: em(10) solid transparent;
			border-bottom: em(10) solid transparent;
			border-right: em(10) solid $color-white;
			left: em(-10);
			top: em(10);
		} // &::before
	} // &__body

	&__tweet {
		display: flex;
	}

	&__media {
		width: 40%;
		padding-left: 2em;
		padding-top: 0.6em;

		flex-grow: 0;
		flex-shrink: 0;
		flex-basis: auto;

		order: 2;
	}

	&__container {
		order: 1;
	}

	&__meta {
		@include clearfix;
		color: $color-silver;
		margin-bottom: em(10);
		font-size: em($body-fontsize - 2);
		a{color: $color-silver;}
	} // &__meta

	&__user {
		float: left;
	} // &__user

	&__time {
		float: right;
	} // &__time

	&__content {
		font-size: em($body-fontsize - 3);
		line-height: 1.4;

		@media screen and (min-width: 380px) {
			font-size: em($body-fontsize);
		}
	} // &__content
} // .twitter-latest
