ol.post-list {
	padding-left:0;
	& li {
		@include clearfix;
		padding-left:0;
		
		&:before{
			display:none;
			
		}
	}
	.post-name{
		margin-bottom:0.2em;
	}
	.post-readmore{
		@include clearfix;
		
		a {
			color: $color-scarlet;
			font-weight: 700;

			.fa {
				font-family: $font-stack-icons;
				font-size: em(9);
				padding-left: em(10);
			}
		} // a
	} //.post-readmore
	.post-excerpt{
		display:inline;
	}
	.post-image{
		float:left;
		display:block;
		margin-right:10px;
		margin-bottom:10px;
		img{
			vertical-align:bottom;
			display:inline-block;
		}
	}
}

.header-search__inner.blog-search {
	width:100%;
}

@media screen and (max-width: 1150px) {
}

@media screen and (max-width: 450px) {
}