.toolbar__limiter {
    float:right;    
    margin-right:em(10);
}
    .limiter__label {
        display:inline-block;
        font-family:$font-stack-default;
        font-size:em(13);
    }
    .limiter__control {
        display:inline-block;
    }
    
      
@media screen and (max-width: 830px) {
    .toolbar__limiter {
        display:none;
    }
    
    .toolbar__mobile--active {
        .toolbar__limiter {
            float:none;
            display:block;
            margin:em(20) em(0) em(0) em(0);
        }
        .limiter__label {
            display:block;
            font-size:em($body-fontsize);
            margin-bottom:em(5);
            font-family:$font-stack-default--bold;
        }
        .limiter__control {
            display:block;
        }
    }
}