.toolbar__amount {
    display:inline-block;
    font-family:$font-stack-default--bold;
    line-height:em(28);
}

@media screen and (max-width: 900px) {
    .toolbar__amount {
        display:none;
    }
}
