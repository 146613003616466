/* notification */

.ampromo-items-add {
    border: 1px solid #ededed;
    padding: 7px;
    text-align: center;
    margin-bottom: 12px;
}

.ampromo-items-add a {
    text-decoration: none;
    border-bottom: 1px dashed;
}

body.checkout-index-index .ampromo-items-add {
    margin: 0 auto 12px;
    position: fixed;
    background: white;
    z-index: 99;
    right: 10px;
    padding: 7px 12px;
    top: 60px;
}

body.checkout-index-index .ampromo-items-add:before {
    content: '';
    display: inline-block;
    background: url(../images/gift.png) no-repeat;
    width: 32px;
    height: 32px;
    vertical-align: middle;
    margin-right: 12px;
}

/* popup */

.ampromo-overlay {
    display: none;
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .8);
    top: 0;
    left: 0;
    z-index: 10000;
    text-align: center;
    direction: ltr;
}

.ampromo-overlay .ampromo-item-buttons,
.ampromo-overlay h2,
.ampromo-overlay h4 {
    text-align: center;
}

.ampromo-overlay h2 {
    margin-top: 0;
}

.ampromo-overlay .close {
	position: absolute;
	top: -18px;
	right: -18px;
	cursor: pointer;
	z-index: 8040;
        @include roundedCorners(em(50));
        overflow:hidden;
        &:before {
            vertical-align:middle;
            display:inline-block;
            content:'\f00d';
            font-family:FontAwesome;
            position:relative;
            width:em(36);
            line-height:em(36);
            color:$color-scarlet;
            text-align:center;
            @include linearGradient3();
        }
}

.ampromo-overlay .close:hover {
    opacity: 1;
    transition: opacity 0.2s ease;
}

.ampromo-items {
    border: 1px solid #ededed;
    background-color: white;
    position: relative;
    margin: 30px auto;
    padding: 24px;
    display: inline-block;
    box-sizing: border-box;
    text-align: left;
}

.ampromo-gallery {
    width: 850px;
    margin: 0 30px;
}

@media only screen and (max-width: 1000px) and (min-width: 701px) {
    .ampromo-overlay .ampromo-gallery {
        width: 570px;
    }
}

.ampromo-overlay .ampromo-gallery[data-count="2"] {
    width: 570px;
}

@media only screen and (max-width: 700px) {
    .ampromo-overlay .ampromo-gallery,
    .ampromo-overlay .ampromo-gallery[data-count="2"] {
        width: 280px;
    }
}

.ampromo-overlay .ampromo-gallery[data-count="1"] {
    width: 280px;
}

@media only screen and (max-width: 400px) {
    .ampromo-overlay .ampromo-gallery {
        margin: 0;
    }

    .ampromo-overlay .slick-prev,
    .ampromo-overlay .slick-next {
        display: none !important;
    }
}

.ampromo-items .ampromo-item {
    box-sizing: border-box;
    padding: 20px;
    border: 1px solid #ededed;
    position: relative;
    margin: 0 8px 7px;
}

.ampromo-items .ampromo-item-image {
    display: block;
    margin: 7px auto;
}

.ampromo-item h4 {
    margin: 0;
}

.ampromo-item .product-options-wrapper {
    margin-bottom: 20px;
}

.ampromo-overlay button {
    box-shadow: none;
    border: none;
}

/* slick */

.ampromo-overlay .slick-prev:before,
.ampromo-overlay .slick-next:before {
    color: black;
    font-size: 42px;
    padding-top: 3px;
}

.ampromo-overlay .slick-prev,
.ampromo-overlay .slick-next {
    padding-top: 3px;
    width: 42px;
    height: 42px;
}

.ampromo-overlay .slick-prev {
    left: -45px;
}

.ampromo-overlay .slick-next {
    right: -45px;
}
