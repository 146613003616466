.footer {
    border-top:solid 5px $color-scarlet;
    p, 
    li {
        font-size:em($body-fontsize - 0.75);
        a {
            color:inherit;
        }
    }
}
    .footer__primary {
        @include linearGradient2();
        .wrapper {
            padding:em(30) em(20);
        }
        h4 {
            color:$color-white;
        }
        p,
        li {
            color:$color-empress;
        }
        ul {
            padding:em(0);
            li {
                position:relative;
                padding-left:em(15);
                &:before {
                    content:'\f054';
                    font-family:FontAwesome;
                    color:$color-scarlet;
                    position:absolute;
                    left:em(0);
                    top:em(1);
                    font-size:em($body-fontsize - 4);
                }
            }
        }
        a {
            @include transition(color);
            &:hover {
                color:$color-white;
                text-decoration:underline;
            }
        }
        .block {
            border-left:solid 1px $color-charcoal;
            border-right:solid 1px $color-black;
            &:first-child {
                border-left:none;
            }
            &:last-child {
                border-right:none;
            }
        }
        .block__content {
            padding-top:em(15);
        }
    }
    .footer__secondary {
        @include linearGradient1();
        .wrapper {
            padding:em(10) em(20);
        }
        p {
            color:$color-white;
        }
        .credit {
            width:200px;
        }
    }
    
    

@media screen and (max-width: 960px) {
    .footer__primary {
        .wrapper {
            padding:em(0);
        }
        .content-columns {
            margin:em(0);
            width:100%;
        }
        .content-columns__col {
            padding:em(0);
            float:none;
            width:auto !important;
        }
        .block {
            border:none;
            border-top:solid 1px $color-charcoal;
            border-bottom:solid 1px $color-black;
            height:auto !important;
            &:first-child {
                border-top:none;
            }
            &:last-child {
                border-bottom:none;
            }
        }
        .block__title {
            padding:em(10) em(40) em(10) em(20);
            position:relative;
            &:after {
                content:'\f078';
                font-family:FontAwesome;
                position:absolute;
                right:em(10);
                top:em(10);
                color:$color-scarlet;
                @include transition();
            }
        }
        .block__title--active {
            &:after {
                @include rotate(180deg);
            }
            &+.block__content {
                display:block;
            }
        }
        .block__content {
            display:none;
            padding:em(0) em(20) em(10);
        }
    }
}

@media screen and (max-width: 700px) {
    .footer__secondary {
        .em-cell {
            display:block;
            text-align:center;
            width:100%;
            &+.em-cell {
                margin-top:em(20);
            }
        }
    }
}