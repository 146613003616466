.homeHero {
	& + #maincontent {
		
		@media screen and (min-width: 1180px){
			margin-top: -55px;
		}
	}
	
    .owl-carousel {
        transform-style: preserve-3d; // fix blur
        .owl-nav {
            width:100%;
            max-width:em($wrapper--width);
            position:absolute;
            top:50%;
            left:50%;
            transform: translate(-50%, -50%);
            .owl-prev {
                left:em(-25);
            }
            .owl-next {
                right:em(-25);
            }
            .owl-prev,
            .owl-next {
                width:em(50);
                border-radius:100%;
                font-size:11px;
            }
        }
        .owl-dots {
            padding-bottom:em(50);
        }
    }
}
    .homeHero__carousel {

    }
    .homeHero__item {
        height:360px;
        background-repeat:no-repeat;
        background-position:center center;
		background-size: cover;
        .wrapper {
            position:relative;
            height:100%;
            transform-style: preserve-3d; // fix blur
            backface-visibility: hidden;
        }
    }
    .homeHero__item+.homeHero__item {
       display:none;
    }
    .homeHero__content {
        padding:em(20);
        background-color:rgba(0,0,0,0.75);
        width:100%;
        max-width:em(460);
        position:absolute;
        top: 50%;
        transform: translateY(-50%);
        p {
            color:$color-white;
        }
        .button {
            margin-top:em(20);
        }
    }
        .homeHero__content--left {
            left:0;
        }
        .homeHero__content--center {
            text-align:center;
            left: 50%;
            transform: translate(-50%, -50%);
        }
        .homeHero__content--right {
            text-align:right;
            right:0;
        }
    .homeHero__text {
        margin-top:em(15);
        font-size:em($body-fontsize + 2);
    }
    
@media screen and (max-width: em($wrapper--width)) {
    .homeHero {
        .owl-carousel {
            .owl-nav {
                .owl-prev {
                    left:em(10);
                }
                .owl-next {
                    right:em(10);
                }
            }
        }
    }
        .homeHero__content--left {
            left:em(25);
        }
        .homeHero__content--right {
            right:em(25);
        }
}

@media screen and (max-width: 900px) {
    .homeHero {
        .owl-carousel {
            .owl-nav {
                display:none !important;
            }
            .owl-dots {
                .owl-dot {
                    width:em(20);
                    height:em(20);
                    &+.owl-dot {
                        margin-left:em(10);
                    }
                }
            }
        }
    }
    .homeHero__content {
        background-color:rgba(0,0,0,0.5);
        padding-top:em(15);
        .button {
            margin-top:em(10);
        }
    }
	.homeHero__content--left {
		left:em(0);
	}
	.homeHero__content--right {
		right:em(0);
	}
    .homeHero__heading {
        font-size:5.5vw;
    }
    .homeHero__text {
		font-size: em(12);
    }
}

.video-carousel{
	.item-video{
		height:300px;
	}
}


@media screen and (max-width: 635px) {
    .homeHero__item {
        height:260px;
    }
}