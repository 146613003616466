.collapsible-nav__title {
    padding:em(20);
    background-color:$color-white;
    border:solid 1px $border-grey;
    border-bottom:solid 2px $border-red;
}
.collapsible-nav__content {
    padding:em(20);
    background-color:$color-white-smoke;
}
.collapsible-nav__items {
    padding:em(0);
}
.collapsible-nav__item {
    &+.collapsible-nav__item {
        margin-top:em(10);
    }
    a {
        color:$body-color;  
        &:hover {
            color:$color-scarlet;  
        }
    }
}