.box-tocart {
    overflow:hidden;
    @include roundedCorners(em(5));
    border:solid 1px $border-grey;
    .fieldset {
        padding:em(20);
        @include linearGradient3();
    }
    .em-cell {
        letter-spacing:em(-4);
        &.addtocart {
            width:em(300);
        }
        * {
            letter-spacing:normal;
        }
    }
    .field.qty {
        display:inline-block;
        vertical-align:middle;
        margin-right:em(10);
        .label {
            display:inline-block;
            font-family:$font-stack-special;
            text-transform:uppercase;
            color:$color-gondola;
            font-size:em($body-fontsize + 3);
            margin-right:em(10);
        }
        .control {
            display:inline-block;
			vertical-align: middle;
            input {
                padding-top:em(15);
                padding-bottom:em(15);
            }
        }
    }
    .actions {
        display:inline-block;
        vertical-align:middle;
        &--red{
            color: #f41a06;
        }
        &--text{
            font-size: 1.71429em;
            margin-right: 4px;
            font-weight: bold;
            display: inline-block;
            font-family: 'open_sans_condensedbold', sans-serif;
        }
    }
}

.actions {
    .action {
        &--red {
            color: #f41a06;
        }
        &--text {
            font-size: 1.71429em;
            margin-right: 4px;
            font-weight: bold;
            display: inline-block;
            font-family: 'open_sans_condensedbold', sans-serif;
        }
    }
}

@media screen and (max-width: 1150px) {
    .box-tocart .fieldset {
        .em-table {
            display:block;
        }
        .em-cell {
            display:block;
            &+.em-cell {
                margin-top:em(20);
            }
            &.addtocart {
                width:100%;
                display:table;
                .field.qty {
                    display:table-cell;
                    vertical-align:middle;
                    text-align:left;
                    width:em(130);
                }
                .actions {
                    display:table-cell;
                    vertical-align:middle;
                    .button {
                        width:100%;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 450px) {
    .box-tocart .fieldset {
        .em-cell.addtocart {
            display:block;
            input.qty {
                max-width:100%;
            }
            .field.qty {
                display:block;
                width:100%;
                .label {
                    display:none;
                }
            }
            .actions {
                display:block;
                margin-top:em(20);
            }
        }
        .control.qty {
            width:100%;
            max-width:100%;
        }
    }
}