h2, .h2 {
    font-size:em(36);
    line-height:1.2em;
    font-family:$font-stack-special;
    color:$typography-heading-color;
    a {
        color:inherit;
    }
}

@media screen and (max-width: 700px) {
    h2, .h2 {
        font-size:6.5vw;
    }
}