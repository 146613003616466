.checkbox__container {
    position:relative;
}
.checkbox {
    position:absolute;
    left:em(-9999);
    &+ .label {
        position:relative;
        display:inline-block;
        padding-left:em(30);
        &:before {
            content:'';
            display:block;
            width:em(20);
            height:em(20);
            background-color:$color-white-smoke;
            border:solid 1px $color-silver;
            @include roundedCorners(em(5));
            box-shadow: inset 0 8px 10px -10px $color-silver;
            position:absolute;
            top:em(-3);
            left:em(0);
        }
        &:after {
            content:'\f00c';
            font-family:FontAwesome;
            position:absolute;
            font-size:em(14);
            width:em(20);
            line-height:em(20);
            text-align:center;
            color:$color-scarlet;
            top:em(-2);
            left:em(0);
            @include transition(opacity);
            @include opacity(0);
        }
        &:hover {
            cursor:pointer;
        }
    }
    &:checked+label:after {
        @include opacity(1);
    }
}