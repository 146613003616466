.advantec-quickview-product-view #maincontent{
	padding-top:0px;
}
.quick-view {
	position: relative;
	border: em(1) solid $border-grey;
	padding: em(20);
	@include clearfix;
	box-shadow: 0px 0px 10px 5px rgba(0,0,0,0.2);
	background: $color-white;
	max-width: 1180px;
	
    &__shop {
		width: 100%;
		float: none;
		
		@media screen and (min-width: 900px) {
			float:right;
			width:50%;
			width:calc(50% - 10px);
			padding-left: em(10);
		}
	}
	
	&__gallery {
		width: 100%;
		float: none;
			
		@media screen and (min-width: 900px) {
			float: left;
			width:50%;
			width:calc(50% - 10px);
		}
			
		.product.media {
			width: 100%;
			margin-bottom: 0;
		}
	}
	
	&__desc {
		font-size: em($body-fontsize - 2);
		
		p {
			margin-bottom: em(10);
		}
		
		ul {
			margin-left: 0;
			padding-left: 0;
			
			li {
				position: relative;
				padding-left: em(20);

				// Bullet
				&::before {
					font-family: $font-stack-icons;
					position: absolute;
					font-size: em(12);
					left: 0;
					top: em(1);
					display: block;
					content: "\f00c";
					width: 10px;
					height: 10px;
					color: $color-scarlet;
				} // &::before
			} // li
		}
	}
	
	&__close {
		color: inherit;
		font-weight: 700;
		font-family: $font-stack-special;
		font-size: em(16);
		position: absolute;
		top: em(10);
		right: em(10);
		
		&::after {
			border: 1px solid $color-scarlet;
			@include roundedCorners(50%);
			width: em(32);
			height: em(32);
			line-height: em(32);
			text-align: center;
			color: $color-scarlet;
			font-family:$font-stack-icons;
			font-size: em(10);
			content: "\f00d";
			display: inline-block;
			vertical-align: middle;
			margin-left: em(10);
		} 
	} // &__close
	
	&__share {
		@include clearfix;
		margin: em(20) 0;
		
		@media screen and (min-width: 900px) {
			margin: em(20) 0 em(0);
		}
	} // &__share
	
	.product-addthis {
		float: left;
		margin-top: 0;
	}
	
	&__full-listing {
		float: right;
	} // &__full-listing
	
	.product__buttons {
		margin-top:em(20);
		&.content-columns {
			width:calc(100% + 10px);
			margin-left: (-10px / 2);
			.content-columns__col {
				float: left;
				padding: 0 (10px / 2);
			}
		}
	}
	
}