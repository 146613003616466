.grid {
    padding:em(30);
    background-color:$color-white-smoke;
}
    .grid__items {
        padding:em(0);
        letter-spacing:em(-4);
        width:calc(100% + #{em(20)});
        margin-top:em(-20);
        margin-left:em(-10);
        display: flex;
        flex-wrap: wrap;
    }
    .grid__item {
        padding:em(10);
        width:33.33%;
        display: flex;
        letter-spacing:normal;
        margin:em(0);
        &:before, &:after {
            display:none;
        }

        .item {
            display: flex;
            flex-direction: column;
        }

        .item__image {
            display:block;
            img {
                width:100%;
                vertical-align:bottom;
            }
        }

        .item__meta {
            margin-top:em(15);

            flex-grow: 1;
            flex-shrink: 0;
            flex-basis: auto;

            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        .item__name {
            font-family:$font-stack-default--bold;
        }
        .item__description {
            margin-top:em(0);

            flex-grow: 1;
            flex-shrink: 0;
            flex-basis: auto;
        }
        .price-box {
            margin-top:em(5);
        }

		&:hover {
			.grid__quick-view { display: block; }
		} // &:hover
    }

	.grid__quick-view {
		display: none;
		position: absolute;
		z-index: 20;
		top: em(10);
		right: em(5);
	}

@media screen and (max-width: 1090px) {
    .grid__item {
        width:50%;
    }
}
@media screen and (max-width: 700px) {
    .grid {
        padding:em(15);
    }
    .grid__items {
        margin-top:em(-10);
    }
    .grid__item {
        width:auto;
        display:block;
        .item {
            padding-left:250px;
            min-height:165px;
            position:relative;
        }
        .item__image {
            width:240px;
            position:absolute;
            top:0px;
            left:0px;
        }
        .item__meta {
            margin:em(0);
        }
    }

	.grid__quick-view {
		display: none !important;
	}
}
@media screen and (max-width: 600px) {
    .grid__item {
        .item {
            padding-left:150px;
            min-height:96px;
        }
        .item__image {
            width:140px;
        }
    }
}
@media screen and (max-width: 460px) {
    .grid__item {
        .item {
            padding:em(0);
            min-height:1px;
        }
        .item__image {
            width:auto;
            position:relative;
            top:auto;
            left:auto;
        }
        .item__meta {
            margin-top:em(10);
        }
    }
}
