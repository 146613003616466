.c-std {
    .grid {
        display: block;
        padding: 10px 0 0 0; // Override default styling
        background-color: transparent; // Override default styling
        width: 100%;

        thead {
            display: none;
        }

        tbody {
            display: block;
        }

        tr {
            display: block;
        }

        td {
            display: block;
        }
    }
}

@media (min-width: 620px) {
    .c-std {
        .grid {
            tr {
                display: flex;
            }

            td {
                flex-grow: 1;
                flex-shrink: 0;
				padding: 0px 10px 10px 0px;
				&:last-child {
					padding-right: 0px;
				}
            }
        }
    }
}

@media (min-width: 830px) {
    .c-std {
        .grid {
            tr {
                display: block;
            }
			
			td{
				padding-right: 0px;
			}
        }
    }
}

@media (min-width: 960px) {
    .c-std {
        .grid {
            tr {
                display: flex;
            }
        }
    }
}