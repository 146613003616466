.opc-block-shipping-information {
    margin-top:em(20);
}
.shipping-information {
    &>div[class*="ship-"] {
        margin-bottom:em(20);
        &:last-child {
            margin-bottom:0;
        }
    }
}
.shipping-information-title {
    padding:em(20) em(50) em(20) em(20);
    border:solid 1px $border-grey;
    border-bottom:solid 2px $border-red;
    @include linearGradient3;
    position:relative;
    .action-edit {
        position:absolute;
        right:em(20);
        top:50%;
        margin-top:-0.5em;
        color:$color-scarlet;
        font-family:$font-stack-default--bold;
        background:transparent;
        text-transform:capitalize;
        &:hover {
            cursor:pointer;
        }
    }
}
.shipping-information-content {
    padding:em(20);
    border:solid 1px $border-grey;
    border-top:none;
    background:$color-white;
    line-height:em($body-fontsize + 7);
}