.control--button {
    position:relative;
    input {
        position:relative;
        z-index:10;
        padding-right:em(85);
    }
    .button {
        position:absolute;
        top:0px;
        right:0px;
        z-index:20;
        span {
            padding-top:em($form-input-padding-vertical + 0.5);
            padding-bottom:em($form-input-padding-vertical + 1.5);
        }
    }
}