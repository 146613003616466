.promo-tile {
	
	&__image {
		position: relative;
		
		img {
			width: 100%;
			display: block;
		} // img
	} // &__image
	
	&__title {
		font-family: $font-stack-default;
		margin-top: 0;
		font-size: em($body-fontsize);
		font-weight: 700;
		color: $color-mortar;
		border: em(1) solid $border-grey;
		border-top: 0 none;
		@include roundedCorners(em(0) em(0) em(5) em(5));
		padding: 20px;
	} // &__title
	
	.video-play {
		top: 50%;
		left: 50%;
		transform: translateY(-50%) translateX(-50%);
	}
	
} // .promo-tile