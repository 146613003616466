.video-play {
	position: absolute;
	display: block;
	background: red;
	width: em(50);
	height: em(50);
	@include roundedCorners(50%);
	background: rgba(255,255,255,.5);
	
	
	&::before {
		content: "";
		position: absolute;
		display: block;
		border-top: 2.7vw solid transparent;
		border-bottom: 2.7vw solid transparent;
		border-left: 4.5vw solid $color-scarlet;
		top: 29%;
		left: 38%;
	} // &::before
	
	
	
/* Variants
---------------------------------------*/
	&--small {
		width: em(50);
		height: em(50);
		
		&::before {
			border-top: em(10) solid transparent;
			border-bottom: em(10) solid transparent;
			border-left: em(15) solid $color-scarlet;
			top: 29%;
			left: 38%;
		} // &::before
	} // &--small
	
	
} // .video-play