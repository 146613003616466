body {
    min-width:300px;
}

$wrapper--width:1230;
.wrapper {
    @include clearfix();

    width: 100%;
    max-width: em($wrapper--width);

    margin: 0 auto;
}

.page-wrapper{    
	height: 100%;
    display: flex;
    width: 100%;
    display: flex;
    flex-direction: column;
}

#maincontent {
	position: relative;
	z-index: 20;
    padding-top:em(30);
    &>.wrapper {
        padding:em(25);
        background-color:$color-white;
        border:solid 1px $border-grey;
        border-top:none;
    }
}

.page-layout-2columns-left {
    .columns {
        .column.main {
            float:right;
            width:100%;
            padding-left:255px;
        }
        .sidebar.sidebar--main {
            float:left;
            width:235px;
            margin-right:-235px;
			clear:left;
        }
    }
}

.page-layout-2columns-right {
    .columns {
        .column.main {
            float:left;
            width:100%;
            padding-right:345px;
        }
        .sidebar.sidebar--main {
            float:right;
            width:325px;
            margin-left:-325px;
        }
    }
}

.a-left {
    text-align:left;
}
.a-center {
    text-align:center;
}
.a-right {
    text-align:right;
}
.em-table {
    display:table;
    &.full {
        width:100%;
    }
}
.em-cell {
    display:table-cell;
    &.v-top {
        vertical-align:top;
    }
    &.v-middle {
        vertical-align:middle;
    }
    &.v-bottom {
        vertical-align:bottom;
    }
}
table {
    &> caption {
        border:solid 1px $border-grey;
        border-bottom:solid 2px $border-red;
        @include linearGradient3();
        text-align:left;
        span {
            font-family:$font-stack-special;
            color:$typography-heading-color;    
            font-size: em(24);
        }
    }
}

$contentColumnGutter: em(40);
.content-columns {
    width:100%;
    width:calc(100% + #{$contentColumnGutter});
    margin-left: -$contentColumnGutter / 2;
    @include clearfix;
	
	// Primarily used for in-page layout. See home page for usage
	&--narrow {
		width:calc(100% + #{$contentColumnGutter /1.5});
		margin-left: -($contentColumnGutter / 3);
		
		.content-columns__col {
			padding: 0 $contentColumnGutter / 3;
		}
		
		& + & {
			.content-columns__col {
				padding-top: $contentColumnGutter / 3;
			}
		}
	}
	
	// Used for nested narrow columns
	&--x-narrow {
		width:calc(100% + #{$contentColumnGutter /3});
		margin-left: -($contentColumnGutter / 6);
		
		.content-columns__col {
			padding: 0 $contentColumnGutter / 6;
		}
		
		& + & {
			.content-columns__col {
				padding-top: $contentColumnGutter / 3;
			}
		}
	}
	
	&--full {
		.content-columns__col {
			padding: 0;
		}
	}
}
    .content-columns__col {
        float: left;
        padding: 0 $contentColumnGutter / 2;
        width: 100%;
		
    }
    .content-columns--two .content-columns__col { 
        width: 50%; 
    } 
    .content-columns--three .content-columns__col { 
        width: 33.33%; 
    } 
    .content-columns--four .content-columns__col { 
        width: 25%; 
    } 
    .content-columns--five .content-columns__col { 
        width: 20%; 
        &.content-columns__col--double {
            width:40%;
        }
    } 
    .content-columns--six .content-columns__col { 
        width: 16.66%; 
        &.content-columns__col--double {
            width:33.33%;
        }
    } 

@media screen and (max-width: 1050px) {
    #maincontent {
        padding-top:em(0);
    }
	
	.content-columns-tablet--one .content-columns__col { 
        width: 100%; 
    } 
	
	.content-columns-tablet--two .content-columns__col { 
        width: 50%; 
    } 
    .content-columns-tablet--three .content-columns__col { 
        width: 33.33%; 
    } 
    .content-columns-tablet--four .content-columns__col { 
        width: 25%; 
    } 
    .content-columns-tablet--five .content-columns__col { 
        width: 20%; 
    } 
    .content-columns-tablet--six .content-columns__col { 
        width: 16.66%; 
        &.content-columns__col--double {
            width:33.33%;
        }
    } 
}

@media screen and (max-width: 830px) {
    .page-layout-2columns-left,
    .page-layout-2columns-right {
        .columns {
            .column.main {
                float:none;
                width:auto;
                padding:em(0);
            }
            .sidebar.sidebar--main {
                float:none;
                width:auto;
                margin:em(0);
                margin-top:em(20);
            }
        }
    }
}

@media screen and (max-width: 700px) {
    #maincontent {
        &>.wrapper {
            padding-left:em(15);
            padding-right:em(15);
        }
    }
	
	.content-columns-mobile--one .content-columns__col { 
        width: 100%; 
		
		& + .content-columns__col {
			margin-top: em(15);
		}
    } 
	
	.content-columns-mobile--two .content-columns__col { 
        width: 50%; 
    } 
    .content-columns-mobile--three .content-columns__col { 
        width: 33.33%; 
    } 
    .content-columns-mobile--four .content-columns__col { 
        width: 25%; 
    } 
    .content-columns-mobile--five .content-columns__col { 
        width: 20%; 
    } 
    .content-columns-mobile--six .content-columns__col { 
        width: 16.66%; 
        &.content-columns__col--double {
            width:33.33%;
        }
    } 
}