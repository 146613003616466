.header-menu {
    border-bottom:solid 5px $color-fire-brick;
    @include linearGradient2();
    padding:em(0) em(24);
    position:relative;
    z-index:2000;
    margin-bottom:em(-30);
}
.nav-primary {
    border-left:solid 1px $color-black;
    border-right:solid 1px $color-charcoal;
}
    .nav-primary__ul {}
        .nav-primary__ul--1 {
            display:table;
            width:100%;
            padding:0px;
            border-left:solid 1px $color-charcoal;
            border-right:solid 1px $color-black;
            border-spacing:0px;
        }
        .nav-primary__ul--2 {
            position:absolute;
            top:100%;
            left:em(0);
            background-color:$color-white;
            padding:em(24);
            border-top:solid 5px $color-fire-brick;
            text-align:left;
			box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.15);
            /*display:none;*/
			visibility: hidden;
			transition-delay: 0.1s;
        }
        .nav-primary__ul--3 {
            padding:em(0);
        }

    .nav-primary__li {}
        .nav-primary__li--1 {
            display:table-cell;
            height:100%;
            vertical-align:middle;
            text-align:center;
            border-right:solid 1px $color-black;
            &.nav-primary__li--parent {
                &>a span {
                    display:inline-block;
                    position:relative;
                    padding-right:em(15);
                    -webkit-backface-visibility: hidden;
                    &:after {
                        content:'\f078';
                        font-family:FontAwesome;
                        font-size:em(10);
                        position:absolute;
                        top:em(0);
                        right:em(0);
                        @include opacity(0.5);
                        @include transition();
                    }
                }
                &[data-columns="1"],
                &[data-columns="2"],
                &[data-columns="3"],
                &[data-columns="4"] {
                    position:relative;
                    .nav-primary__ul--2 {
                        width:em(250);
                    }
                    .nav-primary__li--2 {
                        &+.nav-primary__li--2 > a {
                            position:relative;
                            margin-top:em(48);
                            &:before {
                                content:'';
                                display:block;
                                height:1px;
                                background-color:$color-gainsboro;
                                position:absolute;
                                top:em(-24);
                                left:em(0);
                                right:em(0);
                            }
                        }
                    }
                }
            }
            &+.nav-primary__li--1 {
                border-left:solid 1px $color-charcoal;
            }
            &:last-child {
                border-right:none;
            }
            &.hover,
            &:hover {
                @include linearGradient1();
                border-left-color:transparent;
                &.nav-primary__li--parent {
                    &>a span:after {
                        @include rotate(180deg);
                        @include opacity(1);
                    }
                }
                .nav-primary__ul--2 {
                    /*display:block;*/
					visibility: visible; 
					transition-delay: 0.1s;
                }
            }
            &>a {
                font-family:$font-stack-special;
                color:$color-white;
                font-size:em(14);
                line-height:em(16);
                text-transform:uppercase;
                text-decoration:none;
                display:inline-block;
                padding:em(10);
            }
        }
        .nav-primary__li--2 {
            display:block;
            &>a{
                display:block;
                font-family:$font-stack-special;
                font-size: em(18);
                color:$color-gondola;
                text-decoration:none;
                &:hover {
                    text-decoration:underline;
                }
            }
        }
        .nav-primary__li--3 {
            &>a{
                font-size: em($body-fontsize - 0.25);
                color:$body-color;
                text-decoration:none;
                display:inline-block;
                padding-left:em(20);
                position:relative;
                &:before {
                    content:'\f054';
                    color:$color-scarlet;
                    font-family:FontAwesome;
                    font-size:em(10);
                    display:inline-block;
                    position:absolute;
                    top:em(1.1);
                    left:em(5);
                    @include transition(left);
                }
                &:hover {
                    text-decoration:underline;
                    &:before {
                        text-decoration:none;
                        left:em(10);
                    }
                }
            }
        }
.menu-icon {
    display:none;
    position: absolute;
    top:em(10);
    right:em(10);
    z-index:1001;
    width: em(36);
    height: em(36);
    padding: em(9) em(7);
    @include rotate(0deg);
    @include transition(all, 0.25s);
    @include linearGradient2();
    @include roundedCorners(em(5));
    
    cursor: pointer;
    span {
        display: block;
        position: absolute;
        height:em(3);
        width: 100%;
        background: $color-white;
        opacity: 1;
        left: 0;
        @include rotate(0deg);
        @include transition(all, 0.25s);
        &:nth-child(1) {
            top: 0px;
        }
        &:nth-child(2),
        &:nth-child(3) {
            top: 7px;
        }
        &:nth-child(4) {
            top: 14px;
        }
    }
}
.menu-icon__inner {
    position:relative;
}
.menu-icon--active {
    background-color:$color-fire-brick;
    @include linearGradient2(true);
    &:before,
    &:after {
        /*background-color:@grey-1;*/
    }
    &:before {
        left:-5px;
    }
    &:after {
        right:-5px;
    }
    .menu-icon__inner {
        position:relative;
    }

    span {
        &:nth-child(1) {
            top: 10px;
            width: 0%;
            left: 50%;
        }
        &:nth-child(2) {
            @include rotate(45deg);
        }
        &:nth-child(3) {
            @include rotate(-45deg);
        }
        &:nth-child(4) {
            top: 10px;
            width: 0%;
            left: 50%;
        }
    }
}
@media screen and (max-width: 1050px) {
    .header-menu {
        padding-left:em(0);
        padding-right:em(0);
        border:none;
        background-image: none;
        background-color:transparent;
        position:initial;
        margin:em(0);
    }
    .menu-icon {
        display:block;
    }
    .nav-primary {
        display:none;
        border:none;
    }
    .nav-primary__ul--1 {
        border:none;
    }
        .nav-primary__li--1.nav-primary__li--parent > a span {
            padding-right:em(0);
            &:after {
                display:none;
            }
        }
}
@media screen and (max-width: 400px) {
    .menu-icon {
        right:em(5);
    }
}
