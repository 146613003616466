// Colours
// Colour names are identified here http://www.color-blindness.com/color-name-hue/
$color-scarlet: rgb(244,26,6); // gradient-red--top / required
$color-cardinal: rgb(194,29,23); // gradient-red--bottom
$color-fire-brick: rgb(192,31,36); // nav--border-bottom
$color-snow: rgb(255,246,248);
$color-cloud-burst: rgb(51,60,80);
$color-white-smoke: rgb(245,245,245);
$color-silver: rgb(187,187,187);
$color-mortar: rgb(92,92,92);
$color-white: rgb(255,255,255);
$color-storm-grey: rgb(123,128,139);
$color-malibu: rgb(119,189,226);
$color-gainsboro: rgb(230,230,230);
$color-black: rgb(1,1,1); //gardient-black--bottom
$color-charcoal: rgb(71,71,71); //gardient-black--top
$color-gondola: rgb(53,53,53); //headings
$color-empress: rgb(116,116,116); // footer text
$color-dodger-blue: rgb(64,153,255); // Twitter box blue
$color-columbia-blue: rgb(159,198,255); // Twitter logo light blue

$border-grey: $color-gainsboro;
$border-grey--dark: $color-silver;
$border-red: $color-scarlet;

$color-brand-primary: $color-scarlet;
$color-brand-secondary: $color-gainsboro;

// Font Stacks
$font-stack-default: 'open_sansregular', sans-serif;
$font-stack-default--italic: 'open_sansitalic', sans-serif;
$font-stack-default--bold: 'open_sansbold', sans-serif;
$font-stack-default--bold--italic: 'open_sansbold_italic', sans-serif;
$font-stack-special: 'open_sans_condensedbold', sans-serif;
$font-stack-icons: 'FontAwesome';

// Global
$body-fontsize: 14; // pixels
$body-lineheight: 1;
$body-color: $color-mortar;
$body-background: $color-white-smoke;
$global-animation-duration: 200ms;


/* Grid
----------------------------------------- */
$base:            8px;

$gap:                             $base;        // 008
$gap--two:                         $base * 2;    // 016
$gap--three:                     $base * 3;    // 024
$gap--four:                     $base * 4;    // 032
$gap--five:                     $base * 5;    // 040
$gap--six:                         $base * 6;    // 048
$gap--seven:                     $base * 7;    // 056
$gap--eight:                     $base * 8;    // 064
$gap--nine:                         $base * 9;    // 072
$gap--ten:                         $base * 10;    // 080

$gap--twelve:                    $base * 12;    // 096
$gap--fourteen:                    $base * 14;    // 114
$gap--sixteen:                    $base * 16;    // 128

$gutter:         $gap--four;


// Form
$form-label-font-size: 1em;
$form-input-font-size: 1.6em;
$form-input-padding-vertical: 10;
$form-input-padding-horizontal: 15;

// Typography
$typography-heading-color: $color-gondola;

$browser-context: 				16; // Default

@mixin linearGradient1($invert:null){ // red gradient
    @if $invert {
        @include linearGradient($color-cardinal, $color-scarlet);
    } @else {
        @include linearGradient($color-scarlet, $color-cardinal);
    }
}
@mixin linearGradient2($invert:null){ // red black
    @if $invert {
        @include linearGradient($color-black, $color-charcoal);
    } @else {
        @include linearGradient($color-charcoal, $color-black);
    }
}
@mixin linearGradient3($invert:null){ // red grey
    @if $invert {
        @include linearGradient($color-gainsboro, $color-white);
    } @else {
        @include linearGradient($color-white, $color-gainsboro);
    }
}