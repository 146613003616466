.table-checkout-shipping-method {
    width:100%;
    thead th {
        display:none;
    }
    tbody {
        tr {
            &:first-child {
                td {
                    padding-top:em(10);
                }
            }
            &+ tr td {
                border-top:solid 1px $border-grey;
            }
        }
        td {
            padding:em(20) em(15);
        }
    }
}

@media screen and (max-width: 500px) {
    .table-checkout-shipping-method {
        tbody {
            display:block;
            tr {
                display:block;
                letter-spacing:em(-4);
                &+tr {
                    border-top:solid 1px $border-grey;
                }
            }
            td {
                letter-spacing:normal;
                display:inline-block;
                border:none !important;
                padding-left:0;
                padding-right:0;
                &.col-price .label .price:after {
                    content:'\a0-\a0';
                }
				&.col-carrier{
					display:none;
				}
                &.col-carrier:after {
                    content:'\a0:\a0';
                }
            }
        }
        .radio__container {
            display:inline-block;
        }
    }
}