.header-search,.block-search {
    display:inline-block;
	vertical-align: middle;
}
.header-search__trigger {
    display:none;
}
.header-search__inner {
    position:relative;
    width:em(280);
}
.header-search__input.header-search__input {
    height:em(39);
    padding:em(0) em(50) em(0) em(10);
    font-size:em(13);
	&:-moz-placeholder{
		color:#5c5c5c;
	}
	&:-ms-input-placeholder{
		color:#5c5c5c;
	}
	&::-moz-placeholder{
		color:#5c5c5c;
	}
	&::-webkit-input-placeholder{
		color:#5c5c5c;
	}
	&::placeholder{
		color:#5c5c5c;
	}
}
.header-search__submit {
    position:absolute;
    top:em(0);
    right:em(0);
    z-index: 100!important;
}

@media screen and (max-width: 1150px) {
	.header-search__inner {
		position:relative;
		width:em(200);
	}
}

@media screen and (max-width: 550px) {
    .header-search__trigger {
        display:inline-block;
        vertical-align:middle;
    }
    .header-search__form {
        display:none;
    }
}

@include breakpoint('adult-bear'){
	.header-search {
		position:relative;
	}
}
