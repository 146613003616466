.category {
	
/* Category grid layout
---------------------------------------*/
	
	&__list {
		letter-spacing:em(-4);
		margin: 0;
		padding: 0;
		
		* {
            letter-spacing:normal;
        }
	} //  &__list
	
	
	&__list-item {
		width: 100%;
		display: inline-block;
		vertical-align: top;
		
		@media screen and (min-width: 280px){
			width: 50%;
		}
		
		@media screen and (min-width: 700px){
			width: 33.3333333333%;
		}
		
		@media screen and (min-width: 1000px){
			width: 16.6666666667%;
		}
	} // &__list-item
	

/* Category individual item
---------------------------------------*/

	&__block {
		padding: em(5);
		font-family: $font-stack-default;
		font-size: em($body-fontsize - 2);
		
		img {
			display: block;
			width: 100%;
			margin-bottom: em(12);
		} // img
		
		ul {
			padding-left: 0;
			margin-top: em(10);
			display: none;
			
			@media screen and (min-width: 380px ){
				display: block;
			}
		} // ul
		
		li {
			position: relative;
			padding-left: em(12);
			
			// Bullet
			&::before {
				font-family: $font-stack-icons;
				position: absolute;
				font-size: em(9);
				left: 0;
				top: em(2);
				display: block;
				content: "\f054";
				width: 10px;
				height: 10px;
				color: $color-scarlet;
			} // &::before
		} // li
		
		a {
			color: inherit;
			
			&:hover {
				text-decoration: underline;
			} // &:hover
		} // a
	} // &__block
	
} // .category
