.intro {
	padding: em(20);
	border: em(1) solid $border-grey;
	background: $color-white-smoke;
	@include roundedCorners(em(5));
	
	&__logos {
		@include clearfix
		white-space: nowrap;
		margin-bottom: em(12);
		
		img {
			max-width: 100%;
			display: inline-block;
			vertical-align: middle;
			
			& + img {
				margin-left: em(10);
			}
		} // img
	} // &__logos 
	
	h3 {
		margin-bottom: em(8);
	} // h3
	
	&__content {
		font-size: em($body-fontsize - 3);
		
		@media screen and (min-width: 380px){
			font-size: em($body-fontsize);
		}
	} // &__content
	
	&__more {
		color: $color-scarlet;
		font-weight: 700;
		display: inline-block;
		margin-top: em(15);
		font-size: em($body-fontsize);

		.fa {
			font-family: $font-stack-icons;
			font-size: em(9);
			padding-left: em(10);
		}
	} // &__more
} // .intro