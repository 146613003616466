//.search.results {
//	dl.block {
//		margin: $gap--three $gap--four;
//		.title{
//			display:inline;
//		}
//		dd{
//			display:inline;
//			padding-left: 0;
//			&:after{
//				content:',';
//				margin-left: -3px;
//			}
//			&:last-child{
//				&:after{
//					content:'';
//				}
//			}
//		}
//		a{
//			color:$color-mortar;
//			&:hover{
//				color:$color-scarlet;
//				text-decoration: underline;
//			}
//		}
//	}
//} // .intro
.nojs {
	.block.block-search {
		display:none;
	}
}
.header-menu{
    z-index:1;
}
#search_autocomplete{
    z-index: 100000;
}
.search-open {
	height: 100%;
	overflow-x: hidden;
	width: 100%;
	body {
		height: 100%;
		overflow: hidden;
		position: relative;
		width: 100%;
	}
	.block-search {
		display:block;
	}
	.link.search {
		position: relative;
		&:after {
			content:'';
			display:block;
			height:2px;
			background: $color-brand-secondary;
			position: absolute;
			bottom:-9px;
			width: 100%;
		}
	}
}
.block-search {

	background: $color-white;
	.block-title {
		display:none;
	}
	.block-content {
		height:100%;
	}
}
.search-autocomplete {
	height: 100%;
	width:auto !important;
	overflow-x:  scroll;
}
#search_mini_form {
	.field {
		z-index: 10;
		height: 100%;
	}
	.control {
		height:100%;
		padding-top:50px;
	}
	.actions,
	.label {
		display:none;
	}
}
.amasty-xsearch-loader {
	background: url('../images/icon-search.gif') center no-repeat;
	display:none;
	width:40px;
	height:100%;
	position: absolute;
	top: 0;
	right:0;
	z-index: 100;
	&:not(.amasty-xsearch-hide) {
		display:block;
		& + .amsearch-wrapper-input .amsearch-loupe:before {
			background-image: none;
		}
	}
}
.amsearch-loupe,
.amsearch-close {
	display:none;
}
.amsearch-wrapper-input {
	position: absolute;
	top:0;
	left:0;
	right:0;
	display:flex;
	align-items: center;
	box-shadow: inset 1px 4px 9px -6px;
	input {
		flex:1;
		order:2;
        height: 2.78em;
        padding: 0em 3.57143em 0em 0.71429em;
        font-size: 0.92857em;
	}
	.amsearch-close,
	.amsearch-loupe {
		flex:none;
		order:1;
		text-align: center;
		display: flex;
		align-items: center;
		@include rem(0 $gap, padding);
		&:before {
			content:'';
			display:inline-block;
			width:24px;
			height:24px;
			background-size:16px 16px;
		}
	}
	.amsearch-close {
		order:1;
		&:before {
			background: url('../images/icon-cross.svg') center no-repeat;
		}
	}
	.amsearch-loupe {
		order:3;
		&:before {
			background: url('../images/icon-search.svg') center no-repeat;
		}
	}
}

.amsearch-results {
	background-color: $color-white;
	position: relative;
	border-bottom:solid 4px $color-brand-secondary;
	background-color: $color-white;
	box-shadow: 0 15px 12px 0 rgba(0,0,0,0.22);
}

#search_autocomplete {
	white-space: initial;
	display: block;
	position: absolute;
	left: 0;
	right: 0;
	margin-left: 0;
	height: auto;
	overflow: initial;
	top: 115px;
	.amsearch-leftside {
		width:auto !important;
		display:flex;
		flex-direction: column-reverse;
		@include rem($gap--two, padding);
		#amasty-shopby-product-list {
			margin-left: 0;
			margin-right: 0;
		}
		.amsearch-item-container {
			border-bottom: 1px solid $border-grey--dark;
			margin-bottom: 16px;
			padding-bottom: 16px;

			&.popular_searches {
				order: 1;
				flex: 1;
			}
			&.category {
				order: 2;
			}
			&.recent_searches {
				order: 3;
				flex: 1;
			}
			&.page {
				display:none;
			}
			.amsearch-item {
				@include rem(14);
				@include rem(24, line-height);
				color: rgba($body-color, 0.8);
				a {
					color: rgba($body-color, 0.8);
				}
				&:focus,
				&:hover {
					color: $body-color;
					text-decoration: underline;
					a {
						color: $body-color;
						text-decoration: underline;
					}
				}
				.item-description,
				.amasty-xsearch-num-results {
					display:none;
				}
				& + .amsearch-item {
					@include rem($gap, margin-top);
				}
			}
		}
	}
}
.amsearch-products.-waste,
.amasty-xsearch-block-header {
	@include rem(12);
	@include rem(16, line-height);
	font-weight: 600;
	color:$body-color;
	@include rem($gap, margin-bottom);
}
.amsearch-products.-waste {
	border-top:solid 1px $color-brand-primary;
	@include rem(0 $gap--two, margin);
	@include rem($gap--two, padding-top);
}
#search_autocomplete {
	#amasty-shopby-product-list {
		order:4;
		border-top:solid 1px $color-brand-primary;
		@include rem($gap--two $gap--two 0, margin);
		@include rem($gap--two, padding-top);
		.amsearch-products {
			width:auto !important;
		}
	}
}
.amsearch-item {
	&.product-item {
		display: flex;
		border-bottom:solid 1px $border-grey--dark;
		cursor: pointer;
		@include rem($gap--two, margin-bottom);
		@include rem($gap--two, padding-bottom);
		.amsearch-autocomplete-image {
			flex: none;
		}
		.amsearch-autocomplete-information {
			flex: 1;
			@include rem($gap--two, padding-left);
		}
		.product-item-link {
			font-family: $font-stack-special;
			color: inherit;
			@include rem(24, line-height);
		}
		.amsearch-wrapper-inner {
			@include rem($gap, margin-top);
		}
		.price-box {
			font-family: $font-stack-special;
			.price-label,
			.price {
				color: $color-brand-primary;
			}
		}
	}
	.product-image-photo {
		max-height: 100px;
	}
}
.amsearch-more-results {
	text-align: center;
	a {
		color: inherit;
		font-weight: 600;
		text-decoration: underline;
		@include rem(14);
		&:focus,
		&:hover {
			text-decoration: none;
		}
	}
}
.amsearch-highlight {
	color: $color-brand-primary;
}

.amasty-xsearch-preload {
	display:none;
}

@media screen and (min-width: 550px) {
	#search_autocomplete {
		top: auto;
	}
}

@include breakpoint('teen-bear'){
	.header.content {
		position: relative;
		z-index: 100;
	}
	.block-search {
		display: inline-block;
		position: relative;
		top: auto;
		right: auto;
		bottom: auto;
		left: auto;
		flex:0 0 336px;
        margin-right: 6px;
		@include rem($gap--three, margin-right);
		.block-content {
			height: auto;
		}
	}
	#search_mini_form {
		.field,
		.control {
			height: auto;
			padding: 0;
		}
	}
	.amsearch-wrapper-input {
		position: static;
		top: auto;
		left: auto;
		right: auto;
		width:auto !important;
	}
    .amsearch-form-container.-opened .input-text{
        border:1px solid darkblue;
    }
	.search-autocomplete {
		z-index: 3000;
		height:auto;
		overflow: unset;
		position: absolute;
		width:700px !important;
		right:auto;
		left:50%;
		transform: translateX(-50%);
		top:100%;
		@include rem($gap--four, margin-left);
	}
    .amsearch-results{
        overflow: unset !important;
    }
    .amsearch-close{
        display:none;
    }
	#search_autocomplete {
		@include rem($gap--four, margin-left);
		display: block;
		position: absolute;
		left: initial;
		right: initial;
        top: 46px;
		height: auto;
		overflow: initial;
        box-shadow: none;

		.amsearch-leftside {
			flex-direction: row-reverse;
			.amsearch-item-container {
				border-bottom: none;
				margin-bottom: initial;
				padding-bottom: initial;
			}
		}
		.amsearch-products.-waste,
		#amasty-shopby-product-list {
			border:none;
			margin: 0;
			@include rem($gap--two, padding);
			position: relative;
			&:before {
				content: '';
				display:block;
				width:1px;
				background-color:$border-grey--dark;
				position: absolute;
				@include rem($gap--two, top);
				@include rem($gap--two, bottom);
				left:0;
			}
		}

		.amsearch-products {
			width:60%;
			margin-left: 16px;
		}
	}

	.amsearch-results {
		background-color: $color-white;
		position: relative;
		border-bottom: none;
		border-top:solid 4px $color-brand-secondary;
		background-color: $color-white;
		box-shadow: 0 15px 12px 0 rgba(0,0,0,0.22), 0 19px 38px 0 rgba(0,0,0,0.3);
		margin-top:0px !important;
		&:before {
			content: '';
			display:block;
			width:0;
			height:0;
			border-color:transparent transparent $color-brand-secondary transparent;
			border-width: 8px;
			border-style: solid;
			position: absolute;
			top:-20px;
			left: 50%;
			margin-left:-8px;
		}
	}

}
.amsearch-wrapper-input{
    box-shadow: none;
}
.amsearch-loupe{
    display: none !important;
}
.amsearch-form-container.-opened .amsearch-wrapper-input{
    position: inherit !important;
}
.amsearch-form-container.-opened .input-text{
    padding:0em 3.57143em 0em 0.71429em !important;
}
#search_autocomplete{
    top: 37px;
}
.amsearch-form-container .input-text:focus{
    border:1px solid blue !important;
}
.amsearch-leftside-sidebar{
    display: flex;
    flex-direction: row-reverse;
    flex:1;
}
.amsearch-results .amsearch-item .item-name{
    font-size: 14px !important;
}
.amsearch-item-container{ padding: 0 0px 30px !important;}
.amsearch-form-container.-opened .amsearch-leftside .recent_searches{padding-left:0px;}
#search_autocomplete .amsearch-leftside .amsearch-item-container .amsearch-item + .amsearch-item{

}
.amsearch-results .amsearch-item-container:not(.recent_searches) .amsearch-item .item-name{
text-decoration:none !important;}
.amasty-xsearch-block-header{color:inherit !important; }
.amsearch-form-container.-opened .amsearch-leftside .amsearch-products.product .amsearch-item{padding: 0px 0px !important; padding-bottom: 16px !important;}
#search_autocomplete .amsearch-leftside .amsearch-item-container.popular_searches{padding-bottom: 0px !important;}
@media screen and (max-width: 767px) {
    #search_mini_form .control {
        padding-top: 0px;
    }

    .form .search-autocomplete {
        margin: 0px !important;
    }
    .block-search .search .control {
        position: inherit !important;
    }
    #search_autocomplete {
        top: 48px;
    }
    .amsearch-leftside-sidebar {
        flex-direction: column-reverse;
    }
    #search_autocomplete .amsearch-leftside .amsearch-item-container.recent_searches {
        padding-bottom: 10px !important;
    }
    .amsearch-results {
        padding-top: 0px !important;
    }
    .amsearch-clone-position {
        box-shadow: none !important;
    }
    .amsearch-additional-content {
        width: -webkit-fill-available;
    }
    .amsearch-form-container.-opened .amsearch-leftside .popular_searches .amsearch-item {
        padding: 3px 0px !important;
    }
    .header-search__submit {
        z-index: 100!important;
    }


}
@media screen and (min-width: 768px) {
    .amsearch-wrapper-input {
        position: static;
        top: auto;
        left: auto;
        right: auto;
        width: auto !important;
    }
    .amsearch-results{
        padding:0px;
    }
    .amasty-xsearch-block-header{color:inherit !important;
        @include rem(12);
        @include rem(16, line-height);
        font-weight: 600;
        color:$body-color;
        @include rem($gap, margin-bottom);
        font-size: 12px;
        text-transform: capitalize !important;
    }
    .amasty-xsearch-block-header{
        padding: 10px 0px;
    }
    .amsearch-form-container.-small .amsearch-products{
        order:1 !important;
    }
    .amsearch-results .amsearch-products{
        padding: 0px 0;
    }
    .amsearch-form-container.-small .amsearch-products .amasty-xsearch-block-header{
        padding:0px !important;
    }
    .header-search__form .field.search{width:20em}
    .amsearch-results{margin-top:16px !important;padding:0px !important;}
    .amsearch-autocomplete-image .product-image-photo {
         box-sizing: none;
         border: 0px solid #f1f1f1;
    }
    .amsearch-wrapper-content .product-item-details{
        padding-right: 0px !important;
    }
    .amsearch-form-container.-small.-result .amsearch-item-container.popular_searches .amasty-xsearch-block-header {
        padding: 0px 0px 10px !important;
    }
     .amsearch-results .amsearch-products{
        padding-top:0px !important;
        padding-bottom: 8px !important;
    }
    .amsearch-products .amsearch-more-results{
        padding-top: 0px !important;
    }
    .amsearch-form-container.-small.-result .amsearch-item-container .amasty-xsearch-block-header {
        padding: 0 0px 10px 15px !important;
    }
}
@media screen and (max-width: 767px) {
    .header-search__submit {
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 100 !important;
    }
    .amsearch-products{
        order:0 !important;
    }
    .amsearch-item-container:not(.recent_searches) .amsearch-highlight{
        color: #f41a06 !important;
        font-weight: unset !important;
        font-size: 14px !important;

    }
    .amasty-xsearch-block-header{
        text-transform: capitalize !important;
    }
    .amsearch-products .amasty-xsearch-block-header {
        padding-right: 0px !important;
    }
}
.header-search{display: block;}
.amsearch-products .amsearch-highlight{
    padding: 0px !important;
 background-color: unset !important;
    color: #f41a06 !important;
}
.amsearch-autocomplete-image .product-image-photo{
    border:none !important;
    box-sizing: none !important;
    border: none !important;
}
.amsearch-item-container.recent_searches .amsearch-item
{
    display: block !important;
    box-sizing: unset !important;
    border: none !important;
}
.amsearch-wrapper-content .amsearch-wrapper-inner{
    display: block !important;
}
.amsearch-more-results .amsearch-link{
    border-bottom:none !important;
    color:inherit !important;
}
.amsearch-additional-content{
    flex: 1;
}
.amsearch-form-container.-opened .amsearch-leftside .amsearch-item{
    padding:0px !important;
    margin-right: 0px;
}
.amsearch-item-container.popular_searches .amasty-xsearch-block-header{
    padding-right: 0px !important;
    padding-bottom: 10px !important;
}
.block.block-search{margin-right: 0px;}
.account .block-search .block-content{border:0px;padding:0px;margin:0px}
.account .block.block-search{margin-bottom: 0px; margin-right: 0px}
.account .page-title-wrapper{padding-bottom: 10px;}
.account .title.block-collapsible-nav-title{
    padding: 10px;
    border: solid 1px #e6e6e6;
    border-bottom: solid 2px #f41a06;
    background: white;
    background: linear-gradient(to bottom, white 0%, #e6e6e6 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#000000',GradientType=0 );
    font-family: 'open_sansbold', sans-serif;
    font-weight: bold;
    position: relative;
}
.cookie-status-message {
    display: none;
}
