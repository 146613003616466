.catalog-product-view {
    .grid--bought {
        margin-left:em(-24);
        width:100%;
        width:calc(100% + #{em(49)});
        .grid__items {
            margin-left:-10px;
            width:calc(100% + #{em(20)});
			display: flex;
			flex-wrap: wrap;
        }
        .grid__item {
            width:16.66%;
            padding-left:5px;
            padding-right:5px;
            &:nth-child(n+7) {
                display:none;
            }
        }
    }
    .bought {
    }
    .bought__title {
        text-align:center;
        margin-bottom:em(20);
        overflow:hidden;
        strong {
            display:inline-block;
            position:relative;
            &:before,
            &:after {
                content:'';
                display:block;
                height:2px;
                width:1000px;
                background-color:$color-scarlet;
                position:absolute;
                top:50%;
                margin-top:-1px;
            }
            &:before {
                left:100%;
                margin-left:10px;
            }
            &:after {
                right:100%;
                margin-right:10px;
            }
        }
    }
    .bought__controls {
        text-align:center;
        margin-top:em(20);
    }
}
.checkout-cart-index {
    .block--products {
        float:left;
        width:100%;
        clear:left;
        padding-right:em(340);
        margin-top:em(20);
    }
    .grid--bought {
        background-color:transparent;
        .grid__items {
            width:calc(100% + #{em(40)});
            margin-left:em(-20);
        }
        .grid__item {
            width:50%;
            .item__meta{
                justify-content: flex-start;
                flex-shrink: 1;
            }
        }
        .item {
            position:relative;
            display: flex;
            flex-direction: row;
        }
            .item__image {
                display: flex;
                top:5px;
                left:0;
                width:125px;
                padding-right: em(16);
            }
    }
    .bought {}
        .bought__title {
            border:solid 1px $border-grey;
            padding:em(20);
            .h2 {
                font-size: em(24);
            }
        }
        .bought__content {
            border:solid 1px $border-grey;
            border-top:none;
        }
    .bought__controls {
        display:none;
    }
}

@media screen and (max-width: 1200px) {
    .checkout-cart-index {
        .block--products {
            padding-right:em(295);
        }
    }
}

@media screen and (max-width: 1150px) {
    .catalog-product-view {
        .grid--bought .grid__item {
            width:20%;
            display:inline-block;
            &:nth-child(n+6) {
                display:none;
            }
        }
    }
}
@media screen and (max-width: 1000px) {
    .catalog-product-view {
        .grid--bought .grid__item {
            width:25%;
            display:inline-block;
            &:nth-child(n+5) {
                display:none;
            }
        }
    }
    .checkout-cart-index {
        
        .grid--bought {
            .item {
                padding:0;
            }
            .item__image {
                position:relative;
                top:auto;
                left:auto;
                width:auto;
            }
        }
    }
}
@media screen and (max-width: 850px) {
    .catalog-product-view {
        .grid--bought .grid__item {
            width:33.33%;
            &:nth-child(n+4) {
                display:none;
            }
        }
    }
}
@media screen and (max-width: 700px) {
    .catalog-product-view {
        .grid--bought {
            margin-left:em(-15);
            width:100%;
            width:calc(100% + #{em(30)});
            .grid__items {
                margin:0;
                width:100%;
            }
            .grid__item {
                width:100%;
                padding-left:0;
                padding-right:0;
            }
        }
    }
    .checkout-cart-index {
        .block--products {
            float:none;
            width:auto;
            padding:0;
        }
        .grid--bought {
            .grid__items {
                width:100%;
                margin-left:0;
            }
            .grid__item {
                width:auto;
                padding-left:0;
                padding-right:0;
            }
            .item {
                position:relative;
                padding-left:135px;
                min-height:75px;
            }
                .item__image {
                    position:absolute;
                    top:5px;
                    left:0;
                    width:125px;
                }
        }
    }
}
