.filter-current {}
.filter-current__subtitle {
    padding:em(20);
    background-color:$color-white;
    border:solid 1px $border-grey;
    border-bottom:none;
}
.filter-current__items {
    padding:em(0);
}
.filter-current__item {
    padding:em(0);
    border:solid 1px $border-red;
    line-height:em(16);
    margin:em(0);
    &+.filter-current__item {
        border-top:none;
    }
    &:before {
        display:none;
    }
    a {
        display:block;
        padding:em(10) em(20);
        background-color:$color-snow;
        text-decoration:none;
        color:$color-scarlet;
    }
    span {
        display:block;
        &.filter-label {
            font-family:$font-stack-default--bold;
            position:relative;
            padding-right:em(15);
            &:after {
                content:'\f00d';
                font-family:FontAwesome;
                position:absolute;
                right:em(0);
            }
        }
        &.filter-value {
            font-size:em(13);
        }
    }
}
.filter-current__actions {
    padding:em(20);
    border:solid 1px $border-grey;
    border-top:none;
}