.product.media {
    float:left;
    width:50%;
    width:calc(50% - 10px);
    margin-bottom:em(20);
    .media__main-slider .owl-carousel {
        overflow:hidden;
        .owl-nav {
            .owl-prev {
                @include transition;
                left:em(-30);
            }
            .owl-next {
                @include transition;
                right:em(-30);
            }
        }
        &:hover {
            .owl-nav {
                .owl-prev {
                    left:em(0);
                }
                .owl-next {
                    right:em(0);
                }
            }
        }
		@media screen and (max-width: 700px) {
			.owl-nav {
				.owl-prev {
					@include transition;
					left:0;
				}
				.owl-next {
					@include transition;
					right:0;
				}
			}
		}
    }
}
.media__inner {
    padding:em(20);
    background-color:$color-white-smoke;
}
.media__brand {
    border:solid 1px $border-grey;
    padding:em(5) em(10);
    text-align:center;
    img {
        vertical-align:bottom;
    }
}
.media__main-slider {
    position:relative;
    overflow:hidden;
    .owl-carousel-wrap {
        position:relative;
    }

    .owl-dots {
        display:none !important;
        text-align:center;
        margin-top:em(10);
    }
}
.media__enlarge {
    position:absolute;
    top:em(10);
    right:em(10);
    z-index:100;
    overflow:hidden;
    @include roundedCorners(em(50));
    border:solid 1px $border-grey;
    @include transition();
    &:hover {
        text-decoration: none;
    }
    .fa {
        width:em(35);
        line-height:em(35);
        text-align: center;
        color:$color-scarlet;
        display:block;
        @include linearGradient3();
    }
}
    .media__enlarge--hidden {
        top:em(-40);
        right:em(-40);
        opacity:(0);
    }

.media__sirvspin {
    position:absolute;
    bottom:em(10);
    left:em(10);
    z-index:100;
    @include transition();
    &:hover {
        text-decoration: none;
    }
}
	
.media__thumb-slider {
    margin-top:em(10);
    .item {
        .item__inner {
            display:inline-block;
            position:relative;
            @include transition;
            box-shadow: inset 0px 0px 0px 2px rgba(0,0,0,0);
        }
        &.current .item__inner {
            box-shadow: inset 0px 0px 0px 2px $color-scarlet;
        }
        &:not(.active) .item__inner:hover {
            cursor: pointer;
        }
        img {
            position:relative;
            z-index:-1;
            &:hover {
                cursor:pointer;
            }
        }
    }
}

@media screen and (max-width: 900px) {
    .product.media {
        float:none;
        width:100%;
        padding:em(0);
        margin-bottom:em(0);
        background-color:transparent;
        .owl-carousel {
            .owl-nav {
                .owl-prev {
                    left:em(0);
                }
                .owl-next {
                    right:em(0);
                }
            }
        }
    }
    .media__thumb-slider {
        display:none;
    }
    .product-info-main {
        .product.media {
            width:calc(100% + #{em(42)});
            margin-left:em(-21);
        }
    }
}

@media screen and (max-width: 700px) {
	.media__enlarge {
		display:none;
	}
}