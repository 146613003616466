.opc-block-summary {
    .items-in-cart {
        padding:em(20);
        border:solid 1px $border-grey;
        border-top:none;
        background-color:$color-white;
        &> .title {
            display:block;
            padding:em(5) em(30) em(5) em(0);
            position:relative;
            &:after {
                content:'\f067';
                font-family:FontAwesome;
                color:$color-scarlet;
                position:absolute;
                right:0px;
                @include transition();
            }
            &:hover {
                cursor:pointer;
            }
        }
        &.active > .title:after {
            @include rotate(45deg);
        }
        .subtitle {
            display:none;
        }
    }
    .content.minicart-items {
        padding-top:em(20);
        margin-top:em(20);
        border-top:solid 1px $border-grey;
    }
    ol.minicart-items {
        padding:0;
        .product-item {
            padding:0 em(10);
            &:before {
                display:none;
            }
            &>.product {
                padding:0;
                position:relative;
            }
            &+.product-item {
                padding-top:em(20);
                margin-top:em(20);
                border-top:solid 1px $border-grey;
            }
        }
        .product-image-container {
            display:none;
        }
        .product-item-inner {
            display:table;
            width:100%;
        }
            .product-item-name-block {
                display:table-cell;
                vertical-align:top;
                padding-right:em(20);
            }
                .product-item-name {
                    color:$typography-heading-color;
                }
                .details-qty {
                    .label {
                        font-family:$font-stack-default;
                        color:$body-color;
                        &:after {
                            content:' :';
                        }
                    }
                }
            .subtotal {
                display:table-cell;
                text-align:right;
                width:1%;
                white-space:nowrap;
                vertical-align:top;
                font-family:$font-stack-special;
                margin-top:em(5);
                [data-label] {
                    &:after {
                        content:"("attr(data-label)")";
                    }
                }
            }
            .options {
                &.active {
                    .toggle:after {
                        @include rotate(45deg);
                    }
                }
            }
                .toggle {
                    padding-right:em(20);
                    position:relative;
                    &:hover {
                        cursor:pointer;
                    }
                    &:after {
                        content:'\f067';
                        font-family:FontAwesome;
                        color:$color-scarlet;
                        position:absolute;
                        top:0;
                        right:0;
                        @include transition();
                    }
                }
                .item-options {
                    padding-left:em(20);
                    dt {
                        display:inline;
                        &:after {
                            content:' :';
                        }
                    }
                    dd {
                        display:inline;
                        padding:0;
                        &:after {
                            content: "\A";
                            white-space: pre;
                        }
                    }
                }
    }
    .block.cart-totals {
        margin-top:-1px;
        padding:em(20);
        background-color:$color-white;
        border:solid 1px $border-grey;
        border-top:none;
        position:relative;
        &:before {
            content:'';
            display:block;
            height:1px;
            background-color:$border-grey;
            position:absolute;
            top:0px;
            left:em(20);
            right:em(20);
        }
    }
}
    .opc-block-summary__title {
        padding:em(20);
        border:solid 1px $border-grey;
        border-bottom:solid 2px $border-red;
        @include linearGradient3;
    }
    
@media screen and (max-width: 400px) {
    .opc-block-summary ol.minicart-items {
        .product-item-inner {
            display:block;
        }
            .product-item-name-block,
            .subtotal {
                display:block;
                padding:0;
            }
    }
}