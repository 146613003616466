.button, .actions-toolbar .primary button,.checkout-index-index .actions-toolbar .secondary .action.remind {
    display: inline-block;
    overflow:hidden;
    font-family:$font-stack-special;
    font-size: em(16);
    text-decoration:none;
    color:#fff;
    @include roundedCorners(em(4));
    text-align:center;
    span {
        display:block;
        padding: em(9) em(15);
        @include linearGradient1();
    }
    &:not(.disabled):hover {
        cursor:pointer;
        span {
            background-image:none;
            background-color:#c01f24;
        }
    }
    &.disabled,&:not(.button--active):disabled {
        opacity:0.5;
        pointer-events:none;
        &:hover {
            cursor:default;
        }
    }
}
.button--full {
    width:100%;
}
.button--x-small {
    font-size: em(13);
    span {
        padding-top: em(7.25);
        padding-bottom: em(7.25);
		line-height:1em;
    }
}
.button--small,.checkout-index-index .actions-toolbar .secondary .action.remind {
    font-size: em(14);
    span {
        padding-top: em(8.25);
        padding-bottom: em(8.25);
		line-height:1em;
    }
}
.button--large {
    font-size: em(16);
    span {
        padding-top: em(10.5);
        padding-bottom: em(10.5);
		line-height:1em;
    }
}
.button--x-large {
    font-size: em(20);
    span {
        padding-top: em(11);
        padding-bottom: em(11);
		line-height:1em;
    }

}
.button--side-padding{
    span{
        padding-left:em(64);
        padding-right:em(64);
    }
}
.button--black,.checkout-index-index .actions-toolbar .secondary .action.remind {
    span {
        @include linearGradient2();
    }
    &:not(.disabled):hover span {
        background-image:none;
        background-color:$color-black;
    }
}
.button--grey {
    border:solid 1px $color-silver;
    color:#303030;
    span {
        @include linearGradient3();
    }
    &:not(.disabled):hover {
        border-color:#e5e5e5;
        span {
            background-image:none;
            background-color:#e5e5e5;
        }
    }
}
.button--base-font {
    font-family:$font-stack-default;
    color:$color-scarlet;
}
.button--icon {
    font-size:em($body-fontsize);
    span {
        width:em(37);
        padding:em(10) em(0);
        text-align:center;
        color:$color-white;
    }
    .fa {
        font-size:em(16);
    }
}
.button--icon-right {
    position:relative;
    span {
        padding-right:em(30);
    }
    .fa {
        position:absolute;
        right:em(0);
        width:em(30);
        text-align:center;
        top:50%;
        transform: translateY(-50%);
        font-size:em($body-fontsize + 3);
    }
}
.button--icon-left {
    position:relative;
    span {
        padding-left:em(30);
    }
    .fa {
        position:absolute;
        left:em(0);
        width:em(30);
        text-align:center;
        top:50%;
        transform: translateY(-50%);
    }
}
.button--left {
    @include roundedCorners(em(5) em(0) em(0) em(5));
}
.button--right {
    @include roundedCorners(em(0) em(5) em(5) em(0));
}

.button--flex{
    display: inline-flex;
}
