.opc-wrapper{
	.shipping-address-items{font-size:0;}
	.shipping-address-item {
		border: 2px solid transparent;
		font-size: 14px;
		line-height: 30px;
		margin: 0 0 20px;
		padding: 20px 35px 20px 20px;
		transition: 0.3s border-color;
		width: 50%;
		display: inline-block;
		position: relative;
		vertical-align: top;
		word-wrap: break-word;
		box-sizing: border-box;

		&.selected-item {
			border-color: $color-scarlet;
			&:after {
				background: $color-scarlet;
				color: #ffffff;
				content: '\f00c';
				font-family:$font-stack-icons;
				height: 27px;
				width: 29px;
				font-size: 19px;
				line-height: 21px;
				padding-top: 2px;
				position: absolute;
				right: 0;
				text-align: center;
				top: 0;
			}
			.action-select-shipping-item{
				visibility:hidden; 
			}
		}
		
	}
}

@media screen and (min-width: 1024px) {

	.opc-wrapper .shipping-address-item {
		width: (100% / 3);
	}

}