@font-face {
    font-family: 'open_sansregular';
    src: url('../fonts/OpenSans/OpenSans-Regular-webfont.eot');
    src: url('../fonts/OpenSans/OpenSans-Regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/OpenSans/OpenSans-Regular-webfont.woff') format('woff'),
         url('../fonts/OpenSans/OpenSans-Regular-webfont.ttf') format('truetype'),
         url('../fonts/OpenSans/OpenSans-Regular-webfont.svg#open_sansregular') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'open_sansitalic';
    src: url('../fonts/OpenSans/OpenSans-Italic-webfont.eot');
    src: url('../fonts/OpenSans/OpenSans-Italic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/OpenSans/OpenSans-Italic-webfont.woff') format('woff'),
         url('../fonts/OpenSans/OpenSans-Italic-webfont.ttf') format('truetype'),
         url('../fonts/OpenSans/OpenSans-Italic-webfont.svg#open_sansitalic') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'open_sansbold';
    src: url('../fonts/OpenSans/OpenSans-Bold-webfont.eot');
    src: url('../fonts/OpenSans/OpenSans-Bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/OpenSans/OpenSans-Bold-webfont.woff') format('woff'),
         url('../fonts/OpenSans/OpenSans-Bold-webfont.ttf') format('truetype'),
         url('../fonts/OpenSans/OpenSans-Bold-webfont.svg#open_sansbold') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'open_sansbold_italic';
    src: url('../fonts/OpenSans/OpenSans-BoldItalic-webfont.eot');
    src: url('../fonts/OpenSans/OpenSans-BoldItalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/OpenSans/OpenSans-BoldItalic-webfont.woff') format('woff'),
         url('../fonts/OpenSans/OpenSans-BoldItalic-webfont.ttf') format('truetype'),
         url('../fonts/OpenSans/OpenSans-BoldItalic-webfont.svg#open_sansbold_italic') format('svg');
    font-weight: normal;
    font-style: normal;

}


