h1, .h1 {
    font-size: em(48);
    line-height:1.2em;
    font-family:$font-stack-special;
    color:$typography-heading-color;
    a {
        color:inherit;
    }
}

@media screen and (max-width: 700px) {
    h1, .h1 {
        font-size:7.5vw;
    }
}
