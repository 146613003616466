.header-account__container {
    display:inline-block;
    vertical-align:middle;
    @include roundedCorners(em(5));
    overflow:hidden;
    border:solid 1px $color-silver;
    background-color:$color-white;
}
.header-account {
    padding-left:em(40);
    position:relative;
    ul {
        font-size:em(13);
        padding:em(0) em(8) em(0) em(4);
        letter-spacing:em(-4);
    }
    li {
        display:inline-block;
        letter-spacing:normal;
        position:relative;
        &.header-account__icon + li:before {
            display:none;
        }
        &+li {
            &:before {
                content:'';
                display:block;
                width:1px;
                background-color:$color-mortar;
                position:absolute;
                top:em(8);
                bottom:em(8);
            }
        }
        a {
            display:block;
            padding:em(7.5);
            color:$color-mortar;
            text-decoration:none;
            &:hover {
                text-decoration:underline;
            }
        }
    }
    .button {
        position:absolute;
        top:em(-1);
        left:em(-2);
        
    }
}

@media screen and (min-width: 768px) {
	.nav-sections-item-title {
		display: none;
	}
}

@media screen and (max-width: 1050px) {
    .header-account__container {
        border:none;
    }
    .header-account {
        padding:em(0);
        ul {
            display:none;
        }
        .button {
            position:relative;
            top:auto;
            left:auto;
            @include roundedCorners(em(4));
        }
    }
}
