.site-overlay {
    position:fixed;
    top:0px;
    right:0px;
    bottom:0px;
    left:0px;
    background-color:rgba(0,0,0,0);
    @include transition();
    z-index:-1;
}
    .site-overlay--active {
        z-index:1000;
        background-color:rgba(0,0,0,0.5);
    }

.header {
    background-color:$color-white;
    position:relative;
    z-index:2000;
}
.header__inner {
    padding:em(20) em(25);
    @include clearfix;
}
.header-logos {
    float:left;
    letter-spacing:em(-4);
}
.header-logos__primary {
    display:inline-block;
    vertical-align:bottom;
    letter-spacing:normal;
}
.header-logos__secondary {
    display:inline-block;
    letter-spacing:normal;
    vertical-align:bottom;
    text-align:center;
    max-width:153px;
    margin-left:em(10);
    p {
		text-align:center;
		margin-bottom:em(4);
		font-family:$font-stack-special;
		text-transform:uppercase;
		color:$color-black;
		font-size:em(11);
		line-height: 1.1em;
		padding: 0 20px;
    }
}
.header-content {
    float:right;
}
.header-content__primary {
    letter-spacing:em(-4);
    text-align:right;
	white-space: nowrap;
    &>* {
        letter-spacing:normal;
        &+* {
            margin-left:em(10);
        }
    }
}
.nav-supplementary {
    text-align:right;
    margin-bottom:em(20);
    ul {
        letter-spacing:em(-4);
        padding:em(0);
    }
    li {
        display:inline-block;
        vertical-align:bottom;
        letter-spacing:normal;
        &+ li {
            margin-left:em(15);
        }
    }
    a {
        color:$color-mortar;
        font-family:$font-stack-default--bold;
        text-decoration:none;
        &[href*="mailto"] {
            font-family:$font-stack-default;
            position:relative;
            padding-left:em(23);
            &:before {
                content:'\f0e0';
                font-family:FontAwesome;
                color:$color-scarlet;
                position:absolute;
                left:em(0);
                top:em(0);
                font-size:em(15);
            }
        }
        &[href*="tel"] {
            display:block;
            padding-left:em(15);
            font-family:$font-stack-special;
            font-size:em(25);
            color:$color-charcoal;
            position:relative;
            &:before {
                content:'\f095';
                font-family:FontAwesome;
                color:$color-scarlet;
                position:absolute;
                left:em(0);
                top:em(0);
                font-size:em(15);
            }
        }
    }
}

@media screen and (max-width: 1050px) {
    .header {
        padding:em(10);
        border-bottom:solid 5px $color-fire-brick;
    }
    .header__inner {
        padding:em(0) em(45) em(0) em(0);
    }
    .header-logos__primary {
        img {
            height:35px;
            width:auto;
        }
    }
    .header-logos__secondary {
        display:none;
    }
    .header-content {
        letter-spacing:em(-4);
    }
    .header-content__primary {
        letter-spacing:em(-4);
        display:inline-block;
        vertical-align:middle;
        
    }
    .header-content__secondary {
        letter-spacing:normal;
        display:inline-block;
        vertical-align:middle;
    }
    .nav-supplementary {
        margin:em(0);
        li {
            display:none;
            span {
                display:none;
            }
            &.tel {
                display:inline-block;
                margin:em(0) em(10) em(0) em(0);
                a {
                    display: flex;
                }
                span {
                    display:inline-block;
                    line-height: 26px;
                    background: white;
                    background: linear-gradient(to bottom, white 0%, #e6e6e6 100%);
                    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#000000',GradientType=0 );
                    border-radius: 0em 0.35714em 0.35714em 0em;
                    padding: 0.28571em 0.85714em 0.28571em 0.85714em;
                    border: solid 1px #bbbbbb;
                    border-left: none;
                }
            }
        }
        a[href*="tel"] {           
            @include roundedCorners(em(4));
            overflow:hidden;
            padding:em(0);
            font-size:em($body-fontsize);
            &:before {
                display:inline-block;
                position:relative;
                top:auto;
                left:auto;
                @include linearGradient1();
                width:em(32.5);
                padding:em(6.25) em(0);
                text-align:center;
                color:$color-white;
                font-size:em(16);
            }
        }
    }
}


@media screen and (max-width: 650px) {

    .nav-supplementary {
        li {

            &.tel {
                span {
                    display: none;
                }
            }
        }
    }
}

@media screen and (max-width: 400px) {
    .header {
        padding-left:em(5);
        padding-right:em(5);
    }
    .header__inner {
        padding-right:em(40);
    }
    .header-content__primary {
        &>* + * {
            margin-left:em(5);
        }
    }
    .nav-supplementary li.tel {
        margin-right:em(5);
    }
}
@media screen and (max-width: 340px) {
    .nav-supplementary li.tel {
        display:none;
    }
}