h1 + h1,
h1 + h2,
h1 + h3,
h1 + h4,
h1 + h5,
h1 + h6,
h1 + p,
h1 + ul,
h1 + ol {
    margin-top:20px;
}
h2 + h1,
h2 + h2,
h2 + h3,
h2 + h4,
h2 + h5,
h2 + h6,
h2 + p,
h2 + ul,
h2 + ol {
    margin-top:25px;
}
h3 + h1,
h3 + h2,
h3 + h3,
h3 + h4,
h3 + h5,
h3 + h6,
h3 + p,
h3 + ul,
h3 + ol {
    margin-top:25px;
}
h4 + h1,
h4 + h2,
h4 + h3,
h4 + h4,
h4 + h5,
h4 + h6,
h4 + p,
h4 + ul,
h4 + ol {
    margin-top:25px;
}
h5 + h1,
h5 + h2,
h5 + h3,
h5 + h4,
h5 + h5,
h5 + h6,
h5 + p,
h5 + ul,
h5 + ol {
    margin-top:25px;
}
h6 + h1,
h6 + h2,
h6 + h3,
h6 + h4,
h6 + h5,
h6 + h6,
h6 + p,
h6 + ul,
h6 + ol {
    margin-top:25px;
}
p + h1,
p + h2,
p + h3,
p + h4,
p + h5,
p + h6,
p + p,
p + ul,
p + ol {
    margin-top:20px;
}
p {
    line-height:em($body-fontsize + 7);
}

.c-std {
/* Lists
----------------------------------------- */
	ul {
		margin-bottom: 20px; margin-bottom: 1.250rem;
		
		li {
			position: relative;
			padding-left: 14px; 
			font-size: 14px; font-size: 0.875rem;
			
			// Bullet point
			&::before {
				content: "";
				display: block;
				position: absolute; 
				width: 5px;
				height: 5px;
				background: $color-scarlet;
				border-radius: 50%;
				top: 7px; top: 0.438rem;
				left: 0;
			}
			
			& + li {
				margin-top: 10px; margin-top: 0.625rem;
			}
		}
	} // ul
	
	ol {
		margin-bottom: 20px; margin-bottom: 1.250rem;
		margin-left: 15px;
		
		li {
			font-size: 14px; font-size: 0.875rem;
			
			& + li {
				margin-top: 10px; margin-top: 0.625rem;
			}
		} // li
	} // ol
	
	a{
		color: $color-mortar;
		&:hover{
			color: $color-scarlet;
		}
	}
}