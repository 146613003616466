.breadcrumbs {
    letter-spacing:em(-4);
    padding:em(0) em(0) em(10) em(0);
    margin-top:em(-10);
    .items {
        padding:em(0);
    }
    .item {
        display:inline-block;
        letter-spacing:normal;
        font-size:em($body-fontsize - 1);
        padding-right:em(20);
        position:relative;
        a {
            color:$body-color;
        }
        &:after {
            content:'\f105';
            font-family:FontAwesome;
            position:absolute;
            top:em(0);
            right:em(7);
            color:$color-scarlet;
            line-height:em(22);
            font-weight:bold;
        }
        &:last-child {
            padding-right:em(0);
            &:after {
                display:none;
            }
        }
    }

}
@media screen and (max-width: 700px) {
	.breadcrumbs {
		display:none;
	}
}
