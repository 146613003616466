.form-cart {
    float:left;
    width:100%;
    padding-right:em(340);
    .cart {
        &.table {
            width:100%;
            .col {
                &.image {
                    width:1%;
                    white-space:nowrap;
                    padding-right:em(0);
                    a {
                        display:inline-block;
                    }
                    img {
                        max-width:none;
                        vertical-align:bottom
                    }
                }
                &.item {
                    .product-item-name {
                        color:$typography-heading-color;
                    }
                    .product-item-sku {
                        font-size:em($body-fontsize - 1);
                        margin-top:em(5);
                    }
                    .product-item-delivery {
                        font-size:em($body-fontsize - 1);
                        margin-top:em(5);
                    }
                }
                &.price,
                &.subtotal {
                    min-width:em(125);
                    .price {
                        font-family:$font-stack-special;
                        font-size:em($body-fontsize + 4);
                        color:$typography-heading-color;
                    }
                }
                &.qty {
                    .control {
                        display:inline-block;
                    }
                }
                &.remove {
                    padding-left:em(0);
                    width:1%;
                    white-space:nowrap;
                    .action-delete {
                        display:inline-block;
                        background-color:$color-white;
                        border:solid 1px $border-red;
                        border-radius:em(20);
                        padding:em(7) em(8);
                        &:before {
                            content:'\f00d';
                            font-family:FontAwesome;
                            color:$color-scarlet;
                        }
                        &> span {
                            border: 0;
                            clip: rect(0, 0, 0, 0);
                            height: 1px;
                            margin: -1px;
                            overflow: hidden;
                            padding: 0;
                            position: absolute;
                            width: 1px;
                        }
                    }
                }
            }
            thead {
                th {
                    text-align:left;
                    border-top:solid 1px $border-grey;
                    border-bottom:solid 2px $border-red;
                    padding:em(20);
                    @include linearGradient3();
                    span {
                        font-family:$font-stack-special;
                        font-size:em($body-fontsize + 4);
                        color:$typography-heading-color;
                    }
                    &:first-child {
                        border-left:solid 1px $border-grey;
                    }
                    &:last-child {
                        border-right:solid 1px $border-grey;
                    }
                }
            }
            tbody {
                td {
                    background-color:$color-white-smoke;
                    padding:em(20);
                    border-bottom:solid 1px $color-gainsboro;
                    vertical-align:top;
                    text-align:left;
                }
            }
        }
        &.actions {
            margin-top:em(20);
        }
    }
}

@media screen and (max-width: 1200px) {
    .form-cart {
        padding-right:em(295);
    }
}
@media screen and (max-width: 1100px) {
    .form-cart {
        .cart {
            &.table {
                .col {
                    &.image {
                        padding:0;
                        width:0;
                        * {
                            display:none;
                        }
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 1000px) {
    .form-cart {
        .table-caption {
            @include visible(true);
            padding:em(20);
            border:solid 1px $border-grey;
            border-bottom:solid 2px $border-red;
        }
        .cart.table {
            .col {
                &.image {
                    display:none;
                }
                &.item {
                    border-bottom:none;
                    padding-bottom:em(5);
                    &:before {
                        display:none;
                    }
                }
                &.price {
                    border-bottom:none;
                    padding-top:em(5);
                    padding-bottom:em(5);
                }
                &.qty {
                    border-bottom:none;
                    padding-top:em(5);
                    padding-bottom:em(5);
					.field {
						display:inline-block;
						vertical-align:middle;
					}
                }
                &.subtotal {
                    padding-top:em(5);
                }
                &.remove {
					position:absolute;
					top:0px;
					right:20px;
					&:before{
						display:none;
					}
                }
            }
            thead {
                display:none;
            }
            tbody {
                display:block;
				.product-item-sku {
					margin-top:em(10);
				}
				.product-item-sku__label,
				tr td[data-th]::before {
					font-family:$font-stack-default--bold;
					display:inline-block;
					margin-right:em(5);
					font-size:em($body-fontsize - 1);
					min-width:90px;
				}
                tr {
                    display:block;
					position:relative;
                    td {
                        display:block;
                        &[data-th] {
                            &::before {
                                content:attr(data-th)":";
                            }
                        }
                    }
                }
                span[data-label] {
                    &:after {
                        content:"("attr(data-label)")";
                        font-family:$font-stack-special;
                        color:$typography-heading-color;
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 700px) {
    .cart-summary + .form-cart {
        margin-top:em(20);
    }
    .form-cart {
        width:auto;
        float:none;
        padding-right:0;
    }
}
@media screen and (max-width: 450px) {
    .form-cart .cart.actions {
        .content-columns__col {
            float:none;
            width:auto;
            &+.content-columns__col {
                margin-top:em(10);
            }
        }
        .button {
            display:block;
            width:100%;
        }
    }
}