.pages__items {
    text-align:center;
    letter-spacing:em(-4);
    padding:em(0);
}
    .pages__item {
        display:inline-block;
        letter-spacing:em(0);
        font-size:em(12);
        strong,
        a {
            display:block;
            border:solid 1px $border-grey;
            @include roundedCorners(em(5));
            overflow:hidden;
            &:before,
            span {
                padding:em(5) em(10);
                display:block;
            }
        }
        a {
            color:$body-color;
            &:before,
            span {
                box-shadow: inset 0 8px 10px -10px $color-silver;
                background-color:$color-white-smoke;
            }
        }
        strong {
            @include linearGradient2();
            border-color:transparent;
            color:$color-white;
        }
        &+.pages__item {
            margin-left:em(10);
        }
        .label{
            @include visible();
        }
    }
    .pages__item--previous,
    .pages__item--next {
        a {
            position:relative;
            &:before {
                content:'';
                font-family:FontAwesome;
                color:$color-scarlet;
            }
            span {
                display:none;
            }
        }
    }
    .pages__item--previous a:before {
        content: '\f053';
    }
    .pages__item--next a:before {
        content: '\f054';
    }