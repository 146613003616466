$progressWidth:150px;
$progressItems:5; // double steps minus one as this will accomodate for seps

.breadcrumbs-alt {
    max-width: 720px;
    padding: em(55) em(80) em(15) em(80);
    margin: em(-10) auto em(20);
    position: relative;
    box-sizing: initial;

    &:before {
        content: '';
        display: block;
        height: em(5);
        border: solid 1px $border-grey--dark;
        background-color: $color-white-smoke;
    }

    .breadcrumbs-alt__items {
        padding: em(0);
        position: relative;
    }

    @for $i from 0 through $progressItems {
        .breadcrumbs-alt__item[data-x="#{$i}"] {
            left: calc((100% / (#{$progressItems} - 1)) * (#{$i} - 1));
        }
    }

    .breadcrumbs-alt__item {
        position: absolute;
        bottom: -13px;
        display: block;
        width: $progressWidth;
        text-align: center;

        &:first-child {
            left: calc(-#{$progressWidth} / 2);

            .breadcrumbs-alt__circle {
                &:before {
                    display: none;
                }
            }
        }

        & + .breadcrumbs-alt__item {
            margin-left: calc(-#{$progressWidth} / 2);
        }

        &:last-child {
            right: calc(-#{$progressWidth} / 2);
            left: auto;
            margin: 0px;

            .breadcrumbs-alt__circle {
                &:after {
                    display: none;
                }
            }
        }
    }

    .breadcrumbs-alt__item--sep {
        bottom: -12px;

        .fa {
            font-size: em($body-fontsize - 5);
            color: $color-silver;
            line-height: em($body-fontsize - 2);
            position: absolute;
            top: 50%;
            margin-top: em(-$body-fontsize / 3.5);
            text-indent: em(-3.8);
        }

        .breadcrumbs-alt__circle {
            background: $color-white-smoke;
            width: em(9);
            height: em(8);

            &:before,
            &:after {
                margin-top: em(-3);
            }
        }
    }

    &.breadcrumbs-alt__item--current {
        .breadcrumbs-alt__text {
            opacity: 1;
            text-decoration: underline;
        }

        .breadcrumbs-alt__circle {
            @include linearGradient1();
        }
    }

    &.breadcrumbs-alt__item--complete {
        .breadcrumbs-alt__text {
            opacity: 1;
        }

        .breadcrumbs-alt__circle {
            background: $color-white;
            color: $color-scarlet;
            box-shadow: 0px 0px 0px 1px $border-grey--dark,
            inset 0px 0px 0px 2px $border-red;

            .fa {
                font-size: em($body-fontsize - 1);
            }
        }

        & + .breadcrumbs-alt__item--sep {
            .fa {
                color: $color-scarlet;
            }
        }
    }

    .breadcrumbs-alt__circle {
        text-align: center;
        display: inline-block;
        color: $color-white;
        font-family: $font-stack-default--bold;
        @include linearGradient2();
        border-radius: em(20);
        text-align: center;
        width: em(26);
        line-height: em(26);
        border: solid 5px $color-white-smoke;
        box-sizing: initial;
        box-shadow: 0px 0px 0px 1px $border-grey--dark;
        position: relative;

        &:before,
        &:after {
            content: '';
            display: block;
            width: 20px;
            height: em(5);
            background: $color-white-smoke;
            top: 50%;
            margin-top: em(-1);
            position: absolute;
        }

        &:before {
            right: 100%;
        }

        &:after {
            left: 100%;
        }
    }

    .breadcrumbs-alt__text {
        display: block;
        text-align: center;
        margin-bottom: em(5);
        font-family: $font-stack-special;
        color: $typography-heading-color;
        font-size: em($body-fontsize + 1);
        opacity: 0.5;
    }
}