.product-info-main {
    float:right;
    width:50%;
    width:calc(50% - 10px);
    padding:em(20);
    border:solid 1px $border-grey;
    border-top:solid 2px $border-red;
    margin-bottom:em(20);
}

.product__buttons {
    margin-top:em(20);
    &.content-columns {
        width:calc(100% + 10px);
        margin-left: (-10px / 2);
        .content-columns__col {
            float: left;
            padding: 0 (10px / 2);
        }
    }
}

@media screen and (max-width: 900px) {
    .product-info-main {
        float:none;
        width:100%;
    }
}

@media screen and (max-width: 500px) {
    .product__buttons {
        &.content-columns {
            width:100%;
            margin-left:0;
            .content-columns__col {
                width:auto;
                float:none;
                padding:0;
                &+.content-columns__col {
                    margin-top:em(10);
                }
            }
        }
    }
}
