.product-slider {
	
	&__list {
		padding-left: 0;
	} // &__list
	
	&__list-block {
		padding: 0 em(5) em(50);
		font-family: $font-stack-default;
		font-size: em($body-fontsize - 2);
		
		img {
			margin-bottom: em(12);
		} // img
		
		h3 {
			font-size:4.5vw;
			margin-bottom: em(4);
			
			@media screen and (min-width: 700px) {
				font-size: em(20);
			}
		} // h3
	} // &__list-block 
	
} // .product-slider
